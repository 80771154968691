import React, { useContext } from "react";
import "./App.css";
import Login from "./components/Admin/Login/Login";
import { Context, Provider } from "./components/Context";
import "bootstrap/dist/css/bootstrap.min.css";
import StaffRoute from "./StaffRoute";
import AdminRoute from "./AdminRoute";

function App() {
  const context = useContext(Provider);

  if (context.loading) {
    return <div style={{ color: "#000" }}>loading....</div>;
  }

  return (
    <>
      {context.UserRoleType === "Admin" && context.user ? (
        <AdminRoute />
      ) : context.UserRoleType === "Staff" && context.user ? (
        <StaffRoute />
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
