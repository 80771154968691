import React, { useEffect, useState, useMemo, useRef } from "react";
import "./VCss.css";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { CSVLink } from "react-csv";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useReactToPrint } from "react-to-print";
export default function VoterExcel() {
  const componentRef = useRef(null);
  const [data, setData] = useState([]);
  const [filtered, setfiltered] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [boothOptions, setBoothOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [viewOptions, setViewOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [subcasteOptions, setSubcasteOptions] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("");
  const [printing, setPrinting] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBooth, setSelectedBooth] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedView, setSelectedView] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [selectedSubcaste, setSelectedSubcaste] = useState("");
  const [searchPage, setSearchPage] = useState("");
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;

  const filteredData = useMemo(() => {
    const lowerCaseSearchTerms = searchTerm.toLowerCase().split(" ");

    return data
      .filter((item) => {
        const searchableFields = [
          item.id ? item.id.toString() : "",
          item.name || "",
          item.parent || "",
          item.parentName ? item.parentName.toString() : "",
          item.gender ? item.gender.toString().toLowerCase() : "",
          item.dob || "",
          item.mobileNo ? item.mobileNo.toString() : "",
          item.adharNo ? item.adharNo.toString() : "",
          item.voterId ? item.voterId.toString() : "",
          item.profession || "",
          item.vicile || "",
          item.vehiclestatus || "",
          item.familyID ? item.familyID.toString() : "",
          item.caste || "",
          item.subcaste || "",
          item.membersinfamily ? item.membersinfamily.toString() : "",
          item.familyHeadType ? item.familyHeadType.toString() : "",
          item.states || "",
          item.district || "",
          item.address || "",
          item.location || "",
          item.states1 || "",
          item.district1 || "",
          item.address1 || "",
          item.city1 || "",
          item.booth || "",
          item.roles || "",
          item.political || "",
          item.houseNo || "",
          item.srNo || "",
          moment().diff(moment(item.dob), "years").toString(),
        ];

        return lowerCaseSearchTerms.every((searchTerm) =>
          searchableFields.some((field) =>
            field.toLowerCase().includes(searchTerm)
          )
        );
      })
      .filter((item) => {
        // Apply additional filters
        return (
          (selectedState === "" || item.states === selectedState) &&
          (selectedDistrict === "" || item.district === selectedDistrict) &&
          (selectedCity === "" || item.city1 === selectedCity) &&
          (selectedBooth === "" || item.booth === selectedBooth) &&
          (selectedRole === "" || item.roles === selectedRole) &&
          (selectedView === "" || item.political === selectedView) &&
          (selectedGender === "" || item.gender === selectedGender) &&
          (selectedProfession === "" ||
            item.profession === selectedProfession) &&
          (selectedCaste === "" || item.caste === selectedCaste) &&
          (selectedSubcaste === "" || item.subcaste === selectedSubcaste)
        );
      });
  }, [
    data,
    searchTerm,
    selectedState,
    selectedDistrict,
    selectedCity,
    selectedBooth,
    selectedRole,
    selectedView,
    selectedGender,
  ]);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, itemsPerPage]);
  const csvData = [
    [
      "SR.",
      "Booth",
      "Voter_No",
      "Voter_Name",
      "Relation_Type",
      "Relation_With",
      "Gender",
      "Age",
      "Mobile_No",
      "Voter_Id",
      "Aahaar",
      "House_No",
      "Profession",
      "Vehicle_Status",
      "Caste",
      "Sub_Caste",
      "Members_In_Family",
      "Family_Head_Type",
      "Permanent_District",
      "Permanent_City",
      "Roles",
      "View",
    ],
    ...filtered?.map((item, index) => [
      startingSerialNumber + index,
      item.booth,
      item.srNo,
      item.name,
      item.parent,
      item.parentName,
      item.gender,
      moment().diff(moment(item.dob), "years"),
      item.mobileNo,
      item.voterId,
      item.adharNo,
      item.houseNo,
      item.profession,
      item.vehiclestatus,
      item.caste,
      item.subcaste,
      item.membersinfamily,
      item.familyHeadType === 0
        ? "No"
        : item.familyHeadType === 1
        ? "Yes"
        : "Unknown",
      item.district,
      item.city,
      item.roles,
      item.political,
    ]),
  ];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);

  const fetchStateOptions = async () => {
    try {
      const response = await fetch(
        "https://websiteapi.kmaschool.in/api/panel/getState"
      );
      const data = await response.json();
      setStateOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistrictOptions = async () => {
    try {
      if (selectedState) {
        const response = await fetch(
          `https://websiteapi.kmaschool.in/api/panel/getDistrict/${selectedState}`
        );
        const data = await response.json();
        setDistrictOptions(data);
      } else {
        setDistrictOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCityOptions = async () => {
    try {
      if (selectedDistrict) {
        const response = await fetch(
          `https://websiteapi.kmaschool.in/api/panel/getCity/${selectedDistrict}`
        );
        const data = await response.json();
        setCityOptions(data);
      } else {
        setCityOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBoothOptions = async () => {
    try {
      const response = await fetch(
        "https://websiteapi.kmaschool.in/api/panel/getlist/both"
      );
      const data = await response.json();
      setBoothOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoleOptions = async () => {
    try {
      const response = await fetch(
        "https://websiteapi.kmaschool.in/api/DropDown/api/panel/getrole"
      );
      const data = await response.json();
      setRoleOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchViewOptions = async () => {
    try {
      const response = await fetch(
        "https://websiteapi.kmaschool.in/api/DropDown/api/panel/get/polictical"
      );
      const data = await response.json();
      setViewOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGenderOptions = async () => {
    try {
      const response = await fetch(
        "https://websiteapi.kmaschool.in/api/DropDown/api/panel/getgender"
      );
      const data = await response.json();
      setGenderOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfessionOptions = async () => {
    try {
      const response = await fetch(
        "https://websiteapi.kmaschool.in/api/DropDown/api/panel/getProfassion"
      );
      const data = await response.json();
      setProfessionOptions(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCasteOptions = async () => {
    try {
      const response = await fetch(
        "https://websiteapi.kmaschool.in/api/DropDown/api/panel/getCaste"
      );
      const data = await response.json();
      setCasteOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubcasteOptions = async () => {
    try {
      if (selectedCaste) {
        const response = await fetch(
          `https://websiteapi.kmaschool.in/api/DropDown/api/panel/getSubCaste/${selectedCaste}`
        );
        const data = await response.json();
        setSubcasteOptions(data);
      } else {
        setSubcasteOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 20000);
  }, []);
  useEffect(() => {
    FormView();
  }, [currentPage, pageSize,searchKey]);
  const FormView = () => {
    setIsLoading(true);
    axios
      .get(`https://websiteapi.kmaschool.in/api/panel/getall/filter`, {
        params: {
          States: selectedState,
          District: selectedDistrict,
          city: selectedCity,
          Booth: selectedBooth,
          Political: selectedView,
          gender: selectedGender,
          roles: selectedRole,
          Caste: selectedCaste,
          Subcaste: selectedSubcaste,
          Profession: selectedProfession,
          pageIndex: currentPage,
          pageSize: pageSize,
          searchKey:searchKey,
        },
      })
      .then((response) => {
        setfiltered(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchStateOptions();
    fetchRoleOptions();
    fetchViewOptions();
    fetchGenderOptions();
    fetchProfessionOptions();
    fetchCasteOptions();
    fetchSubcasteOptions();
  }, []);

  useEffect(() => {
    fetchDistrictOptions();
    fetchCityOptions();
    fetchBoothOptions();
    fetchSubcasteOptions();
  }, [selectedState, selectedDistrict, selectedCity, selectedCaste]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const pageSizeOptions = [
    10, 25, 50, 100,  500,1000,1500,2000];

  useEffect(() => {
    fetchStateOptions();
    fetchRoleOptions();
    fetchViewOptions();
    fetchGenderOptions();
  }, []);
  const handlePage = (e) => {
    setSearchPage(e.target.value);
  };
  const [openView, setOpenView] = useState(false);
  const handleClose = () => {
    setOpenView(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setPrinting(true),
    onAfterPrint: () => setPrinting(false),
  });
  return (
    <>
      <Dialog
        variant="h5"
        component="div"
        style={{
          textAlign: "start",
          marginBottom: "20px",
          fontFamily: "Arial, sans-serif",
          fontWeight: "bold",
          color: "blue",
        }}
        open={openView}
        onClose={handleClose}>
</Dialog>
      <div className="d-flex justify-content-between">
      <div>
      <Grid>
          <Grid item>
            <div style={{ cursor: "pointer" }}>
              <CSVLink data={csvData} filename={"VotreList.csv"}>
                <button type="button" className="btn btn-success ">
                  Excel
                </button>
                <DownloadForOfflineIcon />
              </CSVLink>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>  
       <Grid>
          <Grid item>
            <div style={{ cursor: "pointer" }}>
              <button
                type="button"
                className="btn btn-primary"
                style={{ color: "rgb(12, 70, 146)", marginRight: "5px" }}
                onClick={handlePrint}
              >
                <span style={{ color: "white" }}>PDF</span>
              </button>
              <PictureAsPdfIcon onClick={handlePrint} />
            </div>
          </Grid>
        </Grid></div>
       
       
      </div>

      <div className="dropdown-filters">
        <Box sx={{ justifyContent: "space-between" }}>
          <div >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                id="state1"
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
              >
                <MenuItem value="">All States</MenuItem>
                {stateOptions.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>District:</InputLabel>
              <Select
                label="District:"
                value={selectedDistrict}
                onChange={(e) => setSelectedDistrict(e.target.value)}
              >
                <MenuItem value="">All Districts</MenuItem>
                {districtOptions.map((district) => (
                  <MenuItem key={district.id} value={district.id}>
                    {district.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>City:</InputLabel>
              <Select
                label="City:"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                <MenuItem value="">All Cities</MenuItem>
                {cityOptions.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Booth:</InputLabel>
              <Select
                label="Booth:"
                value={selectedBooth}
                onChange={(e) => setSelectedBooth(e.target.value)}
              >
                <MenuItem value="">All Booth</MenuItem>
                {boothOptions.map((both) => (
                  <MenuItem key={both.id} value={both.id}>
                    {both.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Role:</InputLabel>
              <Select
                label="Role:"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <MenuItem value="">All Role</MenuItem>
                {roleOptions.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
           
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>View:</InputLabel>
              <Select
                label="View:"
                value={selectedView}
                onChange={(e) => setSelectedView(e.target.value)}
              >
                <MenuItem value="">All View:</MenuItem>
                {viewOptions.map((view) => (
                  <MenuItem key={view.id} value={view.id}>
                    {view.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Gender:</InputLabel>
              <Select
                label="Gender:"
                value={selectedGender}
                onChange={(e) => setSelectedGender(e.target.value)}
              >
                <MenuItem value="">All Gender:</MenuItem>
                {genderOptions.map((gender) => (
                  <MenuItem key={gender.id} value={gender.id}>
                    {gender.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Profession:</InputLabel>
              <Select
                label="Profession:"
                value={selectedProfession}
                onChange={(e) => setSelectedProfession(e.target.value)}
              >
                <MenuItem value="">All Profession:</MenuItem>
                {professionOptions.map((profession) => (
                  <MenuItem key={profession.id} value={profession.id}>
                    {profession.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Caste:</InputLabel>
              <Select
                label="Caste:"
                value={selectedCaste}
                onChange={(e) => setSelectedCaste(e.target.value)}
              >
                <MenuItem value="">All Caste:</MenuItem>
                {casteOptions.map((caste) => (
                  <MenuItem key={caste.id} value={caste.id}>
                    {caste.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Sub_Caste:</InputLabel>
              <Select
                label="Sub_Caste:"
                value={selectedSubcaste}
                onChange={(e) => setSelectedSubcaste(e.target.value)}
              >
                <MenuItem value="">All Sub_Caste:</MenuItem>
                {subcasteOptions.map((subcaste) => (
                  <MenuItem key={subcaste.id} value={subcaste.id}>
                    {subcaste.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Box>
      </div>
      <div>
        <button type="button" className="btn btn-success" onClick={FormView}>
          View
        </button>
      </div>
      <div className="mx-3 mt-4 d-flex justify-content-between">
      <div>
      <label htmlFor="pageSizeSelect">List Size: </label>
      
        <select
          className="form-select"
          id="pageSizeSelect"
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          {pageSizeOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>
        <div>
    <input
      type="text"
      placeholder="Searching...."
      className="form-control w-100"
      value={searchKey}
      onChange={(e) => setsearchKey(e.target.value)}  
    />
    <div>
      {filteredData.map((item, index) => (
        <div key={index}>
          {item.someField}
        </div>
      ))}
    
  </div>
  </div>
      </div>
      <div
        component={Paper}
        className={`table-container ${printing ? "printing" : ""}`}
        ref={componentRef}
      >
        <table
          style={{ borderRadius: "2px" }}
          className={`table table-bordered mt-5 ${printing ? "printing" : ""}`}
        >
          <caption
            style={{
              captionSide: "top",

              fontWeight: "bold",
              alignItems: "center",
            }}
          >
            MY-CONSTITUENCY
          </caption>
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>SR.</th>
              <th>Booth_No</th>
              <th>Vote_No</th>
              <th>Name</th>
              <th>Relation_With</th>
              <th>Relative_Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Contact</th>
              <th>Voter_ID</th>
              <th>Aadhar</th>
              <th>House_No</th>
              <th>Profession</th>
              <th>Vehicle_Status</th>
              <th>Caste</th>
              <th>Sub_Caste</th>
              <th>Total_In_Family</th>
              <th>Is_Family_Head</th>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Permanent_Address
              </th>
              <th>Role</th>
              {!printing && <th>Voter_View</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div className=" loading-indicator">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
            ) : (
              filtered &&
              filtered.map((item, index) => (
                <tr style={{ textAlign: "center" }} key={item.id}>
                  <td> {item.srNo}</td>
                  <td>{item.booth}</td>
                  <td>{item.srNo}</td>
                  <td>{item.name}</td>
                  <td>{item.parent}</td>
                  <td>{item.parentName}</td>
                  <td>{item.gender}</td>
                  <td>{moment().diff(moment(item.dob), "years")}</td>
                  <td>{item.mobileNo}</td>
                  <td>{item.voterId}</td>
                  <td>{item.adharNo}</td>
                  <td>{item.houseNo}</td>
                  <td>{item.profession}</td>
                  <td>{item.vehiclestatus}</td>

                  <td>{item.caste}</td>
                  <td>{item.subcaste}</td>
                  <td>{item.membersinfamily}</td>
                  <td>
                    {item?.familyHeadType === 0
                      ? "No"
                      : item?.familyHeadType === 1
                      ? "Yes"
                      : "Unknown"}
                  </td>
                  <td>{item.district}</td>
                  <td>{item.city}</td>
                  <td>{item.roles}</td>
                  {!printing && <td>{item.political}</td>}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center align-items-center pagination-container">
        <div className="d-flex justify-content-between align-items-center">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous Page
          </Button>

          <span className="text-align-center">Page {currentPage}</span>
          <Button onClick={() => handlePageChange(currentPage + 1)}>
            Next Page
          </Button>
        </div>
      </div>
    </>
  );
}
