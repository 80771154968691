import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
    return (
        <>
            
                <div className='container my-3 '>
                <h6 id="layoutFooter" >
                     © 2023 <Link  className="linkkma" to='https://kmatechnoware.com/'>Kma Teach</Link>, PVT.   Hisar   All Rights   Reserved. 
                </h6>
                </div>
            
        </>

    )
}

export default Footer