import React, { Component, createContext } from "react";
import jwt_Decode from "jwt-decode";
import api from "./Admin/Api/BaseUrl";
import LoginApi from "./Admin/Api/LoginApi";
import { toast } from "react-toastify";

const Provider = createContext();

class Context extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      loading: true,
      UserRoleType: null,
      loginError: "",
    };
  }

  componentDidMount() {
    this.defaultFunction();
  }

  defaultFunction = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      api.setHeader("Authorization", `Bearer ${token}`);
      await this.decodeToken(token);
      this.setupAutoLogout(); // Set up auto logout timer
    }
    this.setState({ loading: false });
  };

  decodeToken = async (token) => {
    try {
      const decoded = jwt_Decode(token);
      if (decoded.exp) {
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
          localStorage.clear();
          this.setState({ user: null, loading: false });
          this.props.navigate("/login");
          return;
        }

        this.setState({ user: decoded.id });
        this.setState({ UserRoleType: decoded.RoleType });
        this.setState({ UserBoothId: decoded.BothId });
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      localStorage.clear();
      this.setState({ user: null, loading: false });
      this.props.navigate("/login");
    }
  };

  // setupAutoLogout = () => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     const decoded = jwt_Decode(token);
  //     const currentTime = Date.now() / 1000;
  //     const timeRemaining = decoded.exp - currentTime;

  //     // Set up a timer for 12 hours (12 * 60 * 60 seconds)
  //     const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;

  //     // Use the minimum of 12 hours and the actual time remaining
  //     const timeout = Math.min(
  //       twelveHoursInMilliseconds,
  //       timeRemaining * 1000
  //     );

  //     setTimeout(() => {
  //       this.logout();
  //     }, timeout);
  //   }
  // };

  setupAutoLogout = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwt_Decode(token);
      const currentTime = Date.now() / 1000;
      let timeout;
  
      if (decoded.exp) { // Check if token has an expiry time
        const timeRemaining = decoded.exp - currentTime;
        timeout = Math.min(timeRemaining * 1000, 24 * 60 * 60 * 1000); // Minimum of token expiry time or one day in milliseconds
      } else {
        timeout = 24 * 60 * 60 * 1000; // If token doesn't expire, set timeout for one day
      }
  
      setTimeout(() => {
        this.logout();
      }, timeout);
    }
  };
  
  loginhandle = async (data) => {
    try {
      const response = await LoginApi.login(data);
      if (response.ok) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        await this.decodeToken(token);
        this.props.navigate("/");
      }
      if (response.status === 404) {
        toast.error("UserName And Password Is In-Correct");
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  };

  logout = async (callback) => {
    this.setState({ loading: true });

    try {
      await api.logout();
    } catch (error) {
      console.log(error);
    }

    localStorage.removeItem("token");
    this.setState({ user: null, loading: false }, () => {
      if (callback && typeof callback === "function") {
        callback();
      }
      this.props.navigate("/login");
    });
  };

  render() {
    return (
      <Provider.Provider
        value={{
          ...this.state,
          loginhandle: this.loginhandle,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </Provider.Provider>
    );
  }
}

export { Context, Provider };
