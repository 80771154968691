import Index from "./Index";
// add auth user to account

//voter list Entity Report data Get
export const fetchEntity = async (page, pageSize) => {
  return await new Promise((resolve, reject) => {
    Index.getEntity(page, pageSize)
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//Fillter voter list Entity Report data Get
export const fetchEntity1 = async (page, pageSize) => {
  return await new Promise((resolve, reject) => {
    Index.getEntity(page, pageSize)
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};
//Dashboard Report data Get
export const fetchReport = async () => {
  return await new Promise((resolve, reject) => {
    Index.getReport()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
        else localStorage.clear();
      })
      .catch((err) => reject(err));
  });
};


//Staff List data Get
export const fetchStaffList = async () => {
  return await new Promise((resolve, reject) => {
    Index.getStaffReport()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//Block List data Get
export const fetchBlockList = async () => {
  return await new Promise((resolve, reject) => {
    Index.getBlockList()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//Booth List data Get
export const fetchBoothList = async () => {
  return await new Promise((resolve, reject) => {
    Index.getBoothList()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//City List data Get
export const fetchCityList = async () => {
  return await new Promise((resolve, reject) => {
    Index.getCityList()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//District List data GetAll
export const fetchDistrict = async () => {
  return await new Promise((resolve, reject) => {
    Index.getDistrictList()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//District List data GetByID
export const fetchDistrictById = async (id) => {
  return await new Promise((resolve, reject) => {
    Index.getDistrictById(id)
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//State List data GetAll
export const fetchState = async () => {
  return await new Promise((resolve, reject) => {
    Index.getStateList()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};

//MaterType Detail List data GetAll
export const fetchMasterdetail = async () => {
  return await new Promise((resolve, reject) => {
    Index.getMasterdetail()
      .then((resp) => {
        if (resp) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
};
