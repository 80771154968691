import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";

const steps = ["Basic Profile", "Family", "Location", "Political View"];

export default function AddVoter() {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [gender, setGender] = useState([]);
  const [profession, setProfession] = useState([]);
  const [getCaste, setGetCaste] = useState([]);
  const [getsubCaste, setsubGetCaste] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [getbyid, setGetbyid] = useState(null);
  const [states, setStates] = useState([]);
  const [dists, setdistsId] = useState([]);
  const [citys, setCitys] = useState([]);
  const [booth, setBooth] = useState([]);
  const [relation, setRelation] = useState([]);
  const [role, setRole] = useState([]);
  const [vehicletype, setVehicletype] = useState([]);
  const [view, setView] = useState([]);
  const ListId = location.state?.id ?? null;
  const [editid, setEditid] = useState(null);
  const [data, setData] = useState([]);

  const nevigate = useNavigate();
  useEffect(() => {
    fetch("https://websiteapi.kmaschool.in/api/DropDown/api/panel/get/relation")
      .then((response) => response.json())
      .then((data) => setRelation(data));
  }, []);
  useEffect(() => {
    Getvehicletype();
  }, []);
  const Getvehicletype = () => {
    fetch(
      "https://websiteapi.kmaschool.in/api/DropDown/api/panel/get/vehicletype"
    )
      .then((response) => response.json())
      .then((data) => setVehicletype(data));
    setVehicletype(data);
  };
  useEffect(() => {
    GetRole();
  }, []);
  const GetRole = (id) => {
    fetch(`https://websiteapi.kmaschool.in/api/DropDown/api/panel/getrole`)
      .then((response) => response.json())
      .then((data) => setRole(data));
    setRole(data);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    GetGender();
  }, []);
  const GetGender = () => {
    fetch(`https://websiteapi.kmaschool.in/api/DropDown/api/panel/getgender`)
      .then((response) => response.json())
      .then((data) => setGender(data));
    setGender(data);
  };
  // call select value for profession
  useEffect(() => {
    GetProfession();
  }, []);
  const GetProfession = (id) => {
    fetch(
      `https://websiteapi.kmaschool.in/api/DropDown/api/panel/getProfassion`
    )
      .then((response) => response.json())
      .then((data) => setProfession(data));
    setProfession(data);
  };
  // -------------------------------------------caste--------------------------------------------------->>
  useEffect(() => {
    fetch("https://websiteapi.kmaschool.in/api/DropDown/api/panel/getCaste")
      .then((response) => response.json())
      .then((data) => setGetCaste(data));
  }, []);

  const GetSubCaste = (id) => {
    fetch(
      `https://websiteapi.kmaschool.in/api/DropDown/api/panel/getSubCaste/${id}`
    )
      .then((response) => response.json())
      .then((data) => setsubGetCaste(data));
  };
  //-------------------------------------------Permanet Address------------------------------------------>>
  useEffect(() => {
    fetch("https://websiteapi.kmaschool.in/api/panel/getState")
      .then((response) => response.json())
      .then((data) => setState(data));
    setState(data);
  }, []);

  const GetDistrict = (id) => {
    fetch(`https://websiteapi.kmaschool.in/api/panel/getDistrict/${id}`)
      .then((response) => response.json())
      .then((data) => setDistrict(data));
  };
  const GetCity = (id) => {
    fetch(`https://websiteapi.kmaschool.in/api/panel/getcity/${id}`)
      .then((response) => response.json())
      .then((data) => setCity(data));
    setCity(data);
  };
  // -------------------------------------------Current Address---------------------------------------------------
  useEffect(() => {
    getState();
  }, []);

  const getState = () => {
    fetch("https://websiteapi.kmaschool.in/api/panel/getState")
      .then((response) => response.json())
      .then((data) => setStates(data));
  };
  const GetsDistrict = (id) => {
    fetch(`https://websiteapi.kmaschool.in/api/panel/getDistrict/${id}`)
      .then((response) => response.json())
      .then((data) => setdistsId(data));
  };

  const GetCitys = (id) => {
    fetch(`https://websiteapi.kmaschool.in/api/panel/getcity/${id}`)
      .then((response) => response.json())
      .then((data) => setCitys(data));
  };

  useEffect(() => {
    GetView();
  }, []);
  const GetView = () => {
    fetch(
      "https://websiteapi.kmaschool.in/api/DropDown/api/panel/get/polictical"
    )
      .then((response) => response.json())
      .then((data) => setView(data));
    setView(data);
  };

  useEffect(() => {
    Getbooth();
  }, []);
  const Getbooth = () => {
    fetch(`https://websiteapi.kmaschool.in/api/panel/getlist/both`)
      .then((response) => response.json())
      .then((data) => setBooth(data));
    setBooth(data);
  };

  // -------------------------------------------data submit---------------------------------------------------
  const handleSubmit = async (values) => {
    let post = await fetch(
      "https://websiteapi.kmaschool.in/api/panel/post/entity",
      //"http://localhost:5211/api/panel/post/entity",
      {
        method: "post",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (post.ok) {
      toast.success("Data successfully added");
    }
  };
  const EditPriority = async (ListId, values) => {
    let post = await fetch(
      "https://websiteapi.kmaschool.in/api/panel/put/entity/" + ListId,
      //"http://localhost:5211/api/panel/put/entity/" + ListId,
      {
        method: "put",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (post.ok) {
      toast.success("Data Updated Successfully");
      nevigate("/voterlist");

      setEditid(null);
    }
  };

  const handleEdit = async () => {
    let get = await fetch(
      `https://websiteapi.kmaschool.in/api/panel/listbyid/entity/${ListId}`
    );
    if (get.ok) {
      get = await get.json();
      setGetbyid(get);
      GetSubCaste(get.casteId);
      //////////////////permentaddress/////////////////////
      GetDistrict(get.state);
      GetCity(get.districtId);

      ///////////////curentaddress////////////////////////
      GetsDistrict(get.state1);
      GetCitys(get.districtId1);

      //////////////////////////
    }
  };
  useEffect(() => {
    if (ListId != null) {
      handleEdit();
    }
  }, [1]);

  return (
    <>
      <div>
        <span>
          <b>
            <Link
              style={{
                textDecoration: "none",
                float: "right",
                color: "rgb(12, 70, 146)",
              }}
              to="/voterlist"
            >
              Go To Voter List
              <ArrowForwardIcon />
            </Link>
          </b>
        </span>
      </div>

      <div>
        <div style={{ padding: "30px" }}>
          {ListId != null ? (
            <h1 style={{ fontFamily: "Arial, Helvetica", textAlign: "center" }}>
              Update Voter Profile
            </h1>
          ) : (
            <h1 style={{ fontFamily: "Arial, Helvetica", textAlign: "center" }}>
              Add Voter Profile
            </h1>
          )}
        </div>

        <Box display="flex" justifyContent="center">
          <Card
            sx={{ width: "900px", border: "2px solid blue" }}
            variant="outlined"
          >
            <CardContent>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Formik
                enableReinitialize
                initialValues={{
                  name: getbyid != null ? getbyid.name : null,
                  parentTypeId: getbyid != null ? getbyid.parentTypeId : 0,
                  parentName: getbyid != null ? getbyid.parentName : null,
                  mobileNo: getbyid != null ? getbyid.mobileNo : 0,
                  politicalView: getbyid != null ? getbyid.politicalView : 0,
                  vehicleType: getbyid != null ? getbyid.vehicleType : 0,
                  vehiclestatus: getbyid != null ? getbyid.vehiclestatus : null,
                  dob:
                    getbyid != null
                      ? moment(getbyid.dob).format("YYYY-MM-DD")
                      : null,
                  genderId: getbyid != null ? getbyid.genderId : 0,
                  adharNo: getbyid != null ? getbyid.adharNo : null,
                  srNo: getbyid != null ? getbyid.srNo : null,
                  voterId: getbyid != null ? getbyid.voterId : null,
                  familyID: getbyid != null ? getbyid.familyID :null,
                  houseNo: getbyid != null ? getbyid.houseNo : null,
                  membersinfamily:
                    getbyid != null ? getbyid.membersinfamily : null,
                  familyHeadType: getbyid != null ? getbyid.familyHeadType : 0,
                  //////////////////////////Current Address//////////////////////

                  cityId1: getbyid != null ? getbyid.cityId1 : 0,
                  districtId1: getbyid != null ? getbyid.districtId1 : "",
                  state1: getbyid != null ? getbyid.state1 : "",

                  //////////////////////////Permanet Address /////////////////////
                  cityId: getbyid != null ? getbyid.cityId : "",

                  districtId: getbyid != null ? getbyid.districtId : "",
                  state: getbyid != null ? getbyid.state : "",
                  ///////////////////////////////////
                  bothId: getbyid != null ? getbyid.bothId : 0,
                  address: getbyid != null ? getbyid.address : null,
                  address1: getbyid != null ? getbyid.address1 : null,
                  casteId: getbyid != null ? getbyid.casteId : null,
                  subCasteId: getbyid != null ? getbyid.subCasteId : null,
                  professionId: getbyid != null ? getbyid.professionId : 0,
                  role: getbyid != null ? getbyid.role : 0,
                }}
                onSubmit={async (values, { resetForm }) => {
                  await handleSubmit(values, resetForm);
                  resetForm();
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  handleReset,
                  isSubmitting,
                  resetForm,
                }) => (
                  <form id="myform" onSubmit={handleSubmit}>
                    {activeStep === 0 && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: " 1fr 1fr",
                          gap: "16px",
                          mt: 3,
                        }}
                      >
                        <TextField
                          name="name"
                          label="Name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <FormControl>
                          <InputLabel>Relation Type</InputLabel>
                          <Select
                            name="parentTypeId"
                            label="Relation Type"
                            value={values.parentTypeId}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select Relative:---</b>
                            </MenuItem>

                            {Array.isArray(relation) &&
                              relation.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <TextField
                          name="parentName"
                          label="Relative Name"
                          value={values.parentName}
                          onChange={handleChange}
                        />
                        <FormControl>
                          <InputLabel>Gender</InputLabel>
                          <Select
                            name="genderId"
                            label="Gender"
                            value={values.genderId}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select Gender:---</b>
                            </MenuItem>

                            {Array.isArray(gender) &&
                              gender.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <TextField
                          name="dob"
                          label="Date of Birth"
                          type="text"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                          value={values.dob}
                          onChange={handleChange}
                        />
                        <TextField
                          name="mobileNo"
                          label="Contact Number"
                          value={values.mobileNo}
                          onChange={handleChange}
                        />
                        <TextField
                          name="adharNo"
                          label="Aadhar Number"
                          value={values.adharNo}
                          onChange={handleChange}
                        />
                        <TextField
                            name="srNo"
                            label="Voter No"
                            value={values.srNo}
                            onChange={handleChange}
                          />
                        <TextField
                          name="voterId"
                          label="Voter ID"
                          value={values.voterId}
                          onChange={handleChange}
                        />
                        <TextField
                          name="houseNo"
                          label="House No"
                          value={values.houseNo}
                          onChange={handleChange}
                        />
                        <FormControl>
                          <InputLabel>Profession</InputLabel>
                          <Select
                            label="Profession"
                            name="professionId"
                            value={values.professionId}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select Profession:---</b>
                            </MenuItem>

                            {Array.isArray(profession) &&
                              profession.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl>
                          <InputLabel>Vehicle Type</InputLabel>
                          <Select
                            label="Vehicle Type"
                            name="vehicleType"
                            value={values.vehicleType}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select Van Type:---</b>
                            </MenuItem>

                            {Array.isArray(vehicletype) &&
                              vehicletype.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <TextField
                          name="vehiclestatus"
                          label="Vehicle Number"
                          value={values.vehiclestatus}
                          onChange={handleChange}
                        />
                      </Box>
                    )}

                    {activeStep === 1 && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: " 1fr 1fr",
                          gap: "16px",
                          mt: 3,
                        }}
                      >
                        <TextField
                          name="familyID"
                          label="Family ID"
                          value={values.familyID}
                          onChange={handleChange}
                        />

                        <FormControl>
                          <InputLabel>Caste</InputLabel>
                          <Select
                            name="casteId"
                            label="Caste"
                            value={values.casteId}
                            onChange={(e) => {
                              handleChange(e);
                              GetSubCaste(e.target.value);
                            }}
                          >
                            <MenuItem value="">
                              <b>Select Caste:---</b>
                            </MenuItem>
                            {Array.isArray(getCaste) &&
                              getCaste.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <InputLabel>Sub-Caste</InputLabel>
                          <Select
                            name="subCasteId"
                            label="Sub-Caste"
                            value={values.subCasteId}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select Sub-Caste:---</b>
                            </MenuItem>
                            {Array.isArray(getsubCaste) &&
                              getsubCaste.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>

                        <TextField
                          name="membersinfamily"
                          label="Total Family Members"
                          value={values.membersinfamily}
                          onChange={handleChange}
                        />
                        <div
                          style={{
                            paddingLeft: "40px",
                            borderBlockColor: "blue",
                          }}
                        >
                          <div className="form-check">
                            <label>
                              <b>Family_Head </b>
                            </label>
                            <br />
                            <input
                              className="form-check-input "
                              type="radio"
                              name="familyHeadType"
                              id="exampleRadios1"
                              onChange={handleChange}
                              value={"1"}
                              checked={values.familyHeadType === "1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="familyHeadType"
                              id="exampleRadios2"
                              onChange={handleChange}
                              value={"0"}
                              checked={values.familyHeadType === "0"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </Box>
                    )}
                    {activeStep === 2 && (
                      <Box sx={{ textAlign: "center" }} variant="h5 ">
                        {" "}
                        <Typography>Permanet Address</Typography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: " 1fr 1fr",
                            gap: "16px",
                            mt: 3,
                          }}
                        >
                          <FormControl>
                            <InputLabel>State</InputLabel>
                            <Select
                              name="state"
                              label="State"
                              id="selStateId"
                              value={values.state}
                              onChange={(e) => {
                                handleChange(e);
                                GetDistrict(e.target.value);
                              }}
                            >
                              <MenuItem value="">
                                <b>Select State:---</b>
                              </MenuItem>

                              {Array.isArray(state) &&
                                state.map((item, id) => (
                                  <MenuItem key={id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel>District</InputLabel>
                            <Select
                              name="districtId"
                              label="District"
                              id="districtId"
                              value={values.districtId}
                              onChange={(e) => {
                                handleChange(e);
                                GetCity(e.target.value);
                              }}
                            >
                              <MenuItem value="">
                                <b>Select District:---</b>
                              </MenuItem>
                              {Array.isArray(district) &&
                                district.map((item, id) => (
                                  <MenuItem key={id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <InputLabel>City</InputLabel>
                            <Select
                              name="cityId"
                              label="City"
                              id="cityId"
                              value={values.cityId}
                              onChange={handleChange}
                            >
                              <MenuItem value="">
                                <b>Select City:---</b>
                              </MenuItem>
                              {Array.isArray(city) &&
                                city.map((item, id) => (
                                  <MenuItem key={id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <TextField
                            name="address"
                            label="Address-1"
                            value={values.address}
                            onChange={handleChange}
                          />
                        </Box>
                        <Box
                          sx={{ textAlign: "center", marginTop: "20px" }}
                          variant="h5 "
                        >
                          <Typography>Current Address</Typography>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: " 1fr 1fr",
                              gap: "16px",
                              mt: 3,
                            }}
                          >
                            <FormControl>
                              <InputLabel>State</InputLabel>
                              <Select
                                name="state1"
                                label="State"
                                id="state1"
                                value={values.state1}
                                onChange={(e) => {
                                  handleChange(e);
                                  GetsDistrict(e.target.value);
                                }}
                              >
                                <MenuItem value="">
                                  <b>Select State:---</b>
                                </MenuItem>
                                {Array.isArray(states) &&
                                  states.map((item, id) => (
                                    <MenuItem key={id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel>District</InputLabel>
                              <Select
                                name="districtId1"
                                label="District"
                                id="districtId1"
                                value={values.districtId1}
                                onChange={(e) => {
                                  handleChange(e);
                                  GetCitys(e.target.value);
                                }}
                              >
                                <MenuItem value="">
                                  <b>Select District:---</b>
                                </MenuItem>
                                {Array.isArray(dists) &&
                                  dists.map((item, id) => (
                                    <MenuItem key={id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <FormControl>
                              <InputLabel>City</InputLabel>
                              <Select
                                name="cityId1"
                                label="City"
                                id="cityId1"
                                value={values.cityId1}
                                onChange={handleChange}
                              >
                                <MenuItem value="">
                                  <b>Select City:---</b>
                                </MenuItem>
                                {Array.isArray(citys) &&
                                  citys.map((item, id) => (
                                    <MenuItem key={id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <TextField
                              name="address1"
                              label="Address-2"
                              value={values.address1}
                              onChange={handleChange}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {activeStep === 3 && (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: " 1fr 1fr",
                          gap: "16px",
                          mt: 3,
                        }}
                      >
                        <FormControl>
                          <InputLabel>Booth</InputLabel>
                          <Select
                            name="bothId"
                            label="Booth"
                            value={values.bothId}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select Booth:---</b>
                            </MenuItem>
                            {Array.isArray(booth) &&
                              booth.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl>
                          <InputLabel>Role</InputLabel>
                          <Select
                            name="role"
                            label="Role"
                            value={values.role}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select Role:---</b>
                            </MenuItem>
                            {Array.isArray(role) &&
                              role.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl>
                          <InputLabel>Voter View</InputLabel>
                          <Select
                            name="politicalView"
                            label="Voter View"
                            value={values.politicalView}
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <b>Select View:---</b>
                            </MenuItem>
                            {Array.isArray(view) &&
                              view.map((item, id) => (
                                <MenuItem key={id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 3,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{ ml: 1, marginRight: "10px" }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={activeStep === steps.length - 1}
                      >
                        Next
                      </Button>
                      {activeStep === steps.length - 1 && (
                        <>
                          {ListId ? (
                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => EditPriority(ListId, values)}
                              style={{
                                visibility: ListId ? "visible" : "hidden",
                              }}
                              className="btn btn-submit btn-info"
                              sx={{ ml: 1 }}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                              className="btn btn-submit btn-success"
                              style={{
                                visibility: ListId ? "hidden" : "visible",
                              }}
                              sx={{ ml: 1 }}
                            >
                              Submit
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
          />
        </Box>
      </div>
    </>
  );
}
