import React, { useContext, useEffect, useState } from "react";
import Header from "./Header/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer/Footer";
import { Provider } from "../Context";
import "bootstrap/dist/css/bootstrap.min.css";

function Layout() {
  const [isVisible, setIsVisible] = useState(false);
  const context = useContext(Provider);
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = () => {
    if (context.user == null) {
      navigate("/login");
    }
  };

  useEffect(() => {
    // Add a scroll event listener to check if the button should be visible
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="container-fluid px-0">
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            <main id="main" className="main">
              <div className="content-wrapper">
                <Outlet />
                {isVisible && (
                  <a href="#" onClick={scrollToTop} className="back-to-top d-flex align-items-center justify-content-center active">
                    <i className="bi bi-arrow-up-short"></i>
                  </a>
                )}
              </div>
              <Footer />
            </main>
          </div>
          <footer className="footer mt-auto py-3 bg-light">
            <div className="container">
              {/* <span className="text-muted">Footer content here</span> */}
              <span className="text-muted"></span>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Layout;
