import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx"; // Import all named exports as XLSX

export default function Formate() {
  const generateExcelData = () => {
    const data = [
      [
        "Count",
        "Booth",
        "sr",
        "District",
        "Block",
        "Poll Address",
        "Relation name Hindi",
        "Relation Name English",
        "Relation  in Hindi",
        "Field10",
        "Relation English",
        "Field12",
        "Hno",
        "Age",
        "GENDER",
      ],
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, {
      type: "array",
      bookType: "xlsx",
    });

    return excelBuffer;
  };

  const handleDownloadExcel = () => {
    const excelData = generateExcelData();
    const excelFile = new Blob([excelData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelFile, "UpdloadFormate.xlsx");
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleDownloadExcel}
      >
        Download Excel
      </button>
    </div>
  );
}
