import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import "./Voterd.css";
import Editvoter from "../../../StaffLayout/pages/StaffVoter/Editvoter";
import { Button, Dialog, IconButton, Paper } from "@mui/material";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../../Context";
import Index from "../../../Constant/Index";
import axios from "axios";
 

export default function Voterdetail() {
  const componentRef = useRef(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("");
  const [loading, setLoading] = useState(true);
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const navigate = useNavigate();
  const [selectedVoter, setSelectedVoter] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const filteredResults = data.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.srNo.includes(searchTerm)
      );
    });
    setFilteredData(filteredResults);
  }, [data, searchTerm]);

  const context = useContext(Provider);

  useEffect(() => {
    fetchData();
  }, [context.UserBoothId, currentPage, pageSize]);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://websiteapi.kmaschool.in/api/panel/getall/filter`,
          {
            params: {
              Booth: context.UserBoothId,
              pageIndex: currentPage,
              pageSize: pageSize,
            },
          }
        );
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  

  const pageSizeOptions = [10, 25, 50, 100, 200, 500, 1000, 1500, 2000];

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  // useEffect(() => {
  //   console.log("first effect");
  //   const BoothListBlock = async () => {
  //     console.log("first effect BoothListBlock");

  //     const Resp = await Index.BlockListBoothWise(context.UserBoothId);
  //     console.log("first resp of BlockListBoothWise", Resp);
  //     if (!Resp) {
  //       console.log("first error of BlockListBoothWise");
  //       return Resp;
  //     }
  //     setData(Resp.data);
  //   };
  //   BoothListBlock();
  // });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [datasss, setDatasss] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      fetchData()
      setIsLoading(false);
    }, 20000);
  }, []);

  const handleClose = () => {
    setOpenEdit(false);
  };
  const handleEdit = (id) => {
    navigate("/voterdetails", { state: { id } });
    setOpenEdit(true);
  };
  return (
    <div>
      <div component={Paper} className="table-container" ref={componentRef}>
     
        
     <div>
         <h2 className="text-center text-bold "   style={{
             
            
          }} 
>CONSTITUENCY</h2> 
       </div>
        <label htmlFor="pageSizeSelect">List Size: </label>
        <div className="container my-2">
          <select
            className="form-select "
            id="pageSizeSelect"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            {pageSizeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
       
        <input
          type="text"
          className="form-control my-3 "
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
         </div>
        <table
          style={{ borderRadius: "2px", padding: "0px" }}
          className="table table-bordered "
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>SR.</th>

              <th>Action</th>
              <th>Booth_No</th>
              {/* <th> Vote_No</th> */}
              
              <th>Name</th>
              <th>Relation_With</th>
              <th>Relative_Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Contact</th>
              <th>Voter_ID</th>
              <th>Aadhar</th>
              <th>House_No</th>
              <th>Profession</th>
              <th>Vehicle_Status</th>
              <th>Caste</th>
              <th>Sub_Caste</th>
              <th>Total_In_Family</th>
              <th>Is_Family_Head</th>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Permanent_Address
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div className="loading-indicator">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr style={{ textAlign: "center" }} key={item.id}>
                  <td> {item.srNo}</td>
                  <td>
                    <IconButton onClick={() => handleEdit(item.id)}>
                      <Edit />
                    </IconButton>
                  </td>

                  <td>{item.booth}</td>
              
            
                  <td>{item.name}</td>
                  <td>{item.parent}</td>
                  <td>{item.parentName}</td>
                  <td>{item.gender}</td>
                  <td>{moment().diff(moment(item.dob), "years")}</td>
                  <td>{item.mobileNo}</td>
                  <td>{item.voterId}</td>
                  <td>{item.adharNo}</td>
                  <td>{item.houseNo}</td>
                  <td>{item.profession}</td>
                  <td>{item.vehiclestatus}</td>

                  <td>{item.caste}</td>
                  <td>{item.subcaste}</td>
                  <td>{item.membersinfamily}</td>
                  <td>
                    {item?.familyHeadType === 0
                      ? "No"
                      : item?.familyHeadType === 1
                      ? "Yes"
                      : "Unknown"}
                  </td>
                  <td>{item.district}</td>
                  <td>{item.city}</td>
                </tr>
              ))
            ) : (
              data.map((item, index) => (
                <tr style={{ textAlign: "center" }} key={item.id}>
                  <td>{startingSerialNumber + index}</td>
                  <td>
                    <IconButton onClick={() => handleEdit(item.id)}>
                      <Edit />
                    </IconButton>
                  </td>
                  <td>{item.booth}</td>
                  <td>{item.srNo}</td>
                 
                  <td>{item.name}</td>
                  <td>{item.parent}</td>
                  <td>{item.parentName}</td>
                  <td>{item.gender}</td>
                  <td>{moment().diff(moment(item.dob), "years")}</td>
                  <td>{item.mobileNo}</td>
                  <td>{item.voterId}</td>
                  <td>{item.adharNo}</td>
                  <td>{item.houseNo}</td>
                  <td>{item.profession}</td>
                  <td>{item.vehiclestatus}</td>
                  <td>{item.caste}</td>
                  <td>{item.subcaste}</td>
                  <td>{item.membersinfamily}</td>
                  <td>
                    {item?.familyHeadType === 0
                      ? "No"
                      : item?.familyHeadType === 1
                      ? "Yes"
                      : "Unknown"}
                  </td>
                  <td>{item.district}</td>
                  <td>{item.city}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center align-items-center pagination-container">
        <div className="d-flex justify-content-between align-items-center">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous Page
          </Button>
          <span className="text-align-center">Page {currentPage}</span>
          <Button onClick={() => handlePageChange(currentPage + 1)}>
            Next Page
          </Button>
          <Dialog open={openEdit} onClose={handleClose}>
            <Editvoter
              voterData={selectedVoter}
              onClose={() => setOpenEdit(false)}
            />
          </Dialog>
        </div>
      </div>
    </div>
  );
}
