import React, { useEffect, useState, useRef } from "react";
import {
  FormGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Radio,
} from "@mui/material";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";

export default function Booth() {
  const [data, setData] = useState([]);
  const [getbyid, setGetbyid] = useState(null);
  const [editid, setEditid] = useState(null);
  const [options, setOptions] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const formikRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const handleStateChange = (event) => {
    const blockId = event.target.value;
    setSelectedItem(blockId);

    if (blockId) {
      const filtered = data.filter((item) => item.blockId === blockId);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  useEffect(() => {
    fetch("https://websiteapi.kmaschool.in/api/panel/getlist/block")
      .then((response) => response.json())
      .then((data) => setOptions(data));
  }, []);
  const getData = () => {
    axios
      .get("https://websiteapi.kmaschool.in/api/panel/getlist/both")
      //.then((resp) => setData(resp.data))
      .then((resp) => {
        setData(resp.data);
        setFilteredData(resp.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const EditPriority = async (id, values) => {
    try {
      await axios.put(
        `https://websiteapi.kmaschool.in/api/panel/put/both/${id}`,
        values
      );
      toast.success("Data successfully updated");
      setGetbyid(null);
      getData();
      setEditid(null);
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data");
    }
  };

  const handleEdit = async (id, e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://websiteapi.kmaschool.in/api/panel/getbyid/both/${id}`
      );
      if (response.ok) {
        const data = await response.json();
        setGetbyid(data);
        setEditid(id);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await axios.post(
        "https://websiteapi.kmaschool.in/api/panel/post/both",
        values
      );
      toast.success("Data successfully added");
      getData();
      resetForm();
    } catch (error) {
      console.error("Failed to add data:", error);
      toast.error("Failed to add data");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(
        `https://websiteapi.kmaschool.in/api/panel/delete/both/${id}`
      );
      toast.success("Data deleted successfully");
      getData();
    } catch (error) {
      console.error("Failed to delete data:", error);
      toast.error("Failed to delete data");
    }
  };

  const handleView = async (id) => {
    try {
      const response = await fetch(
        `https://websiteapi.kmaschool.in/api/panel/getbyid/both/${id}`
      );
      if (response.ok) {
        const data = await response.json();
        setGetbyid(data);
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const validationSchema = Yup.object().shape({
    blockId: Yup.string().required("Block is required"),
    name: Yup.string().required("Booth name is required"),
    
  });

  const handleResetForm = () => {
    formikRef.current.resetForm();
    setGetbyid(null);
    setEditid(null);
  };
  

 
  return (
    <div>
      <div className="   ">
        <Card className="add-card">
          <CardBody>
            <CardTitle style={{ textAlign: "center" }} tag="h5">
              <b>
                {editid != null ? (
                  <b className="card-title">Update Booth</b>
                ) : (
                  <b className="card-title">Add Booth</b>
                )}
              </b>
            </CardTitle>
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={{
                blockId: getbyid?.blockId || "",
                name: getbyid?.name || "",
                pollAddress: getbyid?.pollAddress || "",
                
                isActive: getbyid ? getbyid.isActive.toString() : "1",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                errors,
              }) => (
                <Form className="row g-3" onSubmit={handleSubmit}>
                  <FormControl className="col-md-10" sx={{ width: "100%" }}>
                    <InputLabel>Block</InputLabel>
                    <Select
                      id="mySelect"
                      name="blockId"
                      label="Block List"
                      value={values.blockId}
                      onChange={(event) => {
                        handleChange(event);
                        handleStateChange(event);
                      }}
                      error={touched.blockId && Boolean(errors.blockId)}
                    >
                      <MenuItem value="">--Select District--</MenuItem>
                      {options.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormGroup className="col-md-10" sx={{ width: "100%" }}>
                    <TextField
                      className="my-3"
                      name="name"
                      label="Insert Booth Number"
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />

                    <TextField
                      className="my-3"
                      name="pollAddress"
                      label="Insert PollAddress "
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      value={values.pollAddress}
                      onChange={handleChange}
                    />
                       <div
                          style={{
                            paddingLeft: "40px",
                            borderBlockColor: "blue",
                          }}
                        >
                          <div className="form-check">
                            <label>
                              <b> IsActive</b>
                            </label>
                            <br />
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              id="exampleRadios1"
                              onChange={handleChange}
                              value={"1"}
                              checked={values.isActive === "1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                            Active
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="isActive"
                              id="exampleRadios2"
                              onChange={handleChange}
                              value={"0"}
                              checked={values.isActive === "0"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              Inactive
                            </label>
                          </div>
                        </div> 
                   
 

                    
                  </FormGroup>
                  <div className="text-center">
                    {editid ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => EditPriority(editid, values)}
                          className="btn btn-submit btn-primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          onClick={handleResetForm}
                          className="btn btn-submit btn-danger mx-2"
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-submit btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <div>
          <section className="section">
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card list-card">
                    <div className="card-body">
                      <h5
                        style={{ textAlign: "center" }}
                        className="card-title"
                      >
                        Booth List
                      </h5>
                      <div className="table-responsive">
                        <table className="table table-hover table-striped table-bordered mt-5">
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>Booth Name</th>
                              <th>Poll Address</th>
                              <th>Block Name</th>
                              <th>IsActive</th>
                              <th colSpan={3} style={{ textAlign: "center" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.pollAddress}</td>

                                <td>{item.block}</td>
                                <td>{item.isActive ? "Active" : "Inactive"}</td>
                                <td>
                                  <IconButton
                                    onClick={(e) => handleEdit(item.id, e)}
                                    color="success"
                                    aria-label="Edit"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </td>
                                <td>
                                  <IconButton
                                    onClick={() => handleDelete(item.id)}
                                    color="warning"
                                    aria-label="Delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </td>
                                
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}
