import {
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState,useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Yup from 'yup';
import { Card, CardBody, CardTitle } from "reactstrap";

export default function Mastertypedetail() {
  const formikRef = useRef(null);
  const [data, setData] = useState([]);
  const [getbyid, setGetbyid] = useState([]);
  const [editid, setEditid] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const handleStateChange = (event) => {
    const parentId = event.target.value;
    setSelectedItem(parentId);

    if (parentId) {
      const filtered = data.filter((item) => item.parentId === parentId);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  useEffect(() => {
    fetch("https://websiteapi.kmaschool.in/api/panel/mastertype/details/list")
      .then((response) => response.json())
      .then((data) => setOptions(data));
    
  }, []);

  const GetData = () => {
    axios
      .get("https://websiteapi.kmaschool.in/api/panel/mastertype/details/parent")
       .then((resp) => {
        setData(resp.data);
        setFilteredData(resp.data);
      });
    }
  useEffect(() => {
    GetData();
  }, []);

  const EditPriority = async (id, values) => {
    try {
      await axios.put(
        `https://websiteapi.kmaschool.in/api/panel/mastertype/details/${id}`,
        values
      );
      document.getElementById("myForm").reset();
      document.getElementById("mySelect").value = "";
      toast.success("Data successfully updated");
      setGetbyid(null);
      GetData();
      setEditid(null);
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data");
    }
  };

 
  const handleEdit = async (id, e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://websiteapi.kmaschool.in/api/panel/mastertype/details/${id}`
      );
      if (response.ok) {
        const data = await response.json();
        setGetbyid(data);
        setEditid(id);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await axios.post(
        "https://websiteapi.kmaschool.in/api/panel/mastertype/details",
        values
      );
      toast.success("Data successfully added");
      GetData();
      document.getElementById("myForm").reset();
      resetForm();
    } catch (error) {
      console.error("Failed to add data:", error);
      toast.error("Failed to add data");
    }
  };

  const handleDelete = async (id) => {
    let Delete = await fetch(
      `https://websiteapi.kmaschool.in/api/panel/mastertype/details/${id}`,
      {
        method: "POST",
      }
    );

    toast.success("Data deleted successfully");
  
    GetData();
  };
  const handleResetForm = () => {
    formikRef.current.resetForm();
    setGetbyid(null);
    setEditid(null);
  };
  const validationSchema = Yup.object().shape({
    parentId: Yup.string().required('Master Type is required'),
    name: Yup.string().required('Name is required'),
  });

  return (
    <div>
      <div >
        <Card className="add-card">
          <CardBody>
          {editid != null ? (
              <CardTitle style={{ textAlign:'center'}}tag="h5">Update Master Parent Details</CardTitle>
            ) : (
              <CardTitle style={{ textAlign:'center'}}tag="h5">Insert Master Parent Details</CardTitle>
            )}
             
            <Formik
             innerRef={formikRef}
              enableReinitialize
              initialValues={{
                name: getbyid?.name || "",
                parentId:getbyid?.parentId || "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleReset,
                isSubmitting,
                resetForm,
                touched,
                errors,
              }) => (
                <Form id="myForm" className="row g-3" onSubmit={handleSubmit}>
              
                    <FormControl  className="col-md-10" sx={{width: "100%" }}>
                      <InputLabel>Master Type</InputLabel>
                      <Select
                        id="mySelect"
                        type="text"
                        name="parentId"
                        label="Master Type"
                        value={values.parentId}
                        onChange={(event) => {
                          handleChange(event);
                          handleStateChange(event);
                        }}
                        error={touched.parentId && Boolean(errors.parentId)}
                        helperText={touched.parentId && errors.parentId}
                      >
                        <MenuItem value="">--Select Master Type--</MenuItem>
                        {options.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                   
                    <FormGroup className="col-md-10" sx={{width: "100%" }}>
                    
                    <TextField
                    
                      type="text"
                      name="name"
                      label="Name"
                      InputLabelProps={{ shrink: true }}
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </FormGroup>
                  <div className="text-center">
                    {editid ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => EditPriority(editid, values)}
                          className="btn btn-submit btn-primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          onClick={handleResetForm}
                          className="btn btn-submit btn-danger mx-2"
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-submit btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <div>
          <section className="section">
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card list-card">
                    <div className="card-body">
                      <h5 style={{ textAlign:'center'}} className="card-title">Master Type Parent Details List</h5>
                      <div className="table-responsive">
                      <table className="table table-hover table-striped table-bordered mt-5">
                        <thead>
                          <tr>
                            <th>Sr.No</th>
                            {/* <th>ID</th> */}
                            <th>Parent Name</th>
                             
                            <th>Master Name</th>
                            <th colSpan={2} style={{ textAlign: "center" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {/* <td>{item.id}</td> */}
                              <td>{item.name}</td>
                              
                              <td>{item.parent}</td>
                              <td>
                                <IconButton
                                  onClick={(e) => handleEdit(item.id, e)}
                                  color="success"
                                  aria-label="Edit"
                                >
                                  <EditIcon />
                                </IconButton>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => handleDelete(item.id)}
                                  color="warning"
                                  aria-label="Delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}
