import React, { useContext, useEffect } from "react";
import { Provider } from "../../../Context";
 
import "./Welcomepage.css";
function Welcomepage() {
  const context = useContext(Provider);
  return (
    <div className="container mt-5">
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div  className="card">
          <div style={{height:'380px'}} className="card-body">
            <h4 className="card-title text-center">Welcome to Constituency <br /> Booth no. {context.UserBoothId}</h4>
            <img style={{width:200}} src="/static/media/img1.067abcc127f7452f5c98.png" alt="constituency" />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Welcomepage;
