import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  CardTitle,
  CardBody,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

export default function Mastertype() {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = () => {
    axios
      .get("https://websiteapi.kmaschool.in/api/panel/getlist/mastertype")
      .then((resp) => setData(resp.data));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (item) => {
    setSelectedItem(item);
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (selectedItem) {
      // Update existing item
      const { id } = selectedItem;

      try {
        await axios.put(
          `https://websiteapi.kmaschool.in/api/panel/put/mastertype/${id}`,
          values
        );

        toast.success("Data updated successfully");
        getData();
        resetForm();
        setSelectedItem(null);
      } catch (error) {
        toast.error("Failed to update data");
      }
    } else {
      // Submit new item
      try {
        await axios.post(
          "https://websiteapi.kmaschool.in/api/panel/post/mastertype",
          values
        );

        toast.success("Data added successfully");
        getData();
        resetForm();
      } catch (error) {
        toast.error("Failed to add data");
      }
    }
  };

  const handleDelete = async (id) => {
    let Delete = await fetch(
      `https://websiteapi.kmaschool.in/api/panel/delete/mastertype/${id}`,
      {
        method: "POST",
      }
    );
    toast.success("Data deleted successfully");
    getData();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    isActive: Yup.string().required("Status is required"),
  });

  return (
    <div>
      <div >
        <Card className="add-card">
          <CardBody>
          {selectedItem !=null?(
            <CardTitle style={{ justifyContent:'center',textAlign:'center'}}tag="h5">Update Master Type</CardTitle>
          ):(
            <CardTitle style={{ justifyContent:'center',textAlign:'center'}}tag="h5">ADD Master Type</CardTitle>
          )}
            <Formik
              enableReinitialize
              initialValues={{
                name: selectedItem ? selectedItem.name : "",
                isActive: selectedItem ? selectedItem.isActive.toString() : "1",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleReset, touched, errors }) => (
                <Form className="row g-3" style={{width: "100%" }}>
                  <FormGroup>
                    <TextField
                       sx={{width: "100%" }}
                      type="text"
                      name="name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </FormGroup>
                  <div
                          style={{
                            paddingLeft: "40px",
                            borderBlockColor: "blue",
                          }}
                        >
                          <div className="form-check">
                            <label>
                              <b> IsActive</b>
                            </label>
                            <br />
                            <input
                              className="form-check-input "
                              type="radio"
                              name="isActive"
                              id="exampleRadios1"
                              onChange={handleChange}
                              value={"1"}
                              checked={values.isActive === "1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                            Active
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="isActive"
                              id="exampleRadios2"
                              onChange={handleChange}
                              value={"0"}
                              checked={values.isActive === "0"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              Inactive
                            </label>
                          </div>
                        </div>
                  <div className="text-center">
                    {selectedItem ? (
                      <>
                        <Button type="submit" color="primary">
                          Update
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => {
                            handleReset();
                            setSelectedItem(null);
                          }}
                          className="mx-2"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>

        <Card className="show-card">
          <CardBody>
            <CardTitle style={{ justifyContent:'center',textAlign:'center'}}tag="h5">Master Type List</CardTitle>
            <Table className="table table-hover table-striped table-bordered mt-5">
              <thead>
                <tr>
                  <th>SR. No</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th colSpan={2} style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item,index) => (
                  <tr key={item.id}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>
                      <IconButton
                        onClick={() => handleEdit(item)}
                        color="success"
                        aria-label="Edit"
                      >
                        <EditIcon />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton
                        onClick={() => handleDelete(item.id)}
                        color="warning"
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}
