import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css"
import { Card, CardContent } from '@mui/material';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputAdornment, IconButton } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { Provider } from "../../Context";
import logoImg from "../../../assets/img/logo1.png"

export default function Login() {
  const context = useContext(Provider);
  const [showPassword, setShowPassword] = useState(false);

  const loginhandles = async (data) => {
    try {
      const newData = {
        emailId: data.usercode,
        password: data.password,
      };
      const resp = await context.loginhandle(newData);
     
    } catch (error) {
      toast.error("Login failed. Please try again."); // Show error toast
    }
  };

   

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Box className="login_main">
        <Container className="login_main-container" component="main" maxWidth="xs" sx={{ padding: '50px 0 0' }}>
          <Box >
            <Card sx={{
              marginTop: 8,
              borderRadius: "30px",
              padding: "20px",
              display: 'flex',
              justifyContent: "center",
              alignItems: 'center',
            }}>
              <CardContent>
                <Box>
                  <Typography component="span" className="heading">
                    <img src={logoImg} alt="logo" width={150} />
                  </Typography>
                  <Box >
                    <Formik
                      initialValues={{ usercode: "", password: "" }}
                    
                      onSubmit={loginhandles}  
                    >
                      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="usercode"
                            label="Username"
                            name="usercode"
                            autoComplete="usercode"
                            autoFocus
                            variant="outlined"
                            style={{ marginBottom: "1rem" }}
                            value={values.usercode}
                            onChange={handleChange}
                             required
                          />
                          <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"} // Toggle between "text" and "password" type
                            id="password"
                            autoComplete="password"
                            variant="outlined"
                            style={{ marginBottom: "1rem" }}
                            value={values.password}
                            onChange={handleChange}
                            required
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton edge="end" onClick={handleTogglePassword}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          /> 
                          <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                            style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                          />
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{
                              backgroundColor: "#1976d2",
                              color: "#fff",
                              marginTop: "1rem",
                              marginBottom: "0.5rem",
                              "&:hover": {
                                backgroundColor: "#115293",
                              },
                            }}
                            disabled={isSubmitting}
                          >
                            Sign In
                          </Button>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <ToastContainer position="top-right" autoClose={3000} />
        </Container>
      </Box>
    </div>
  );
}

