import React, { useState } from "react";
import { utils, read } from "xlsx";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import Formate from "./FormateExcel/Formate";

export default function UploadExcel() {
  const [excelData, setExcelData] = useState([]);
  const [excelError, setExcelError] = useState("");
  const [file, setFile] = useState("");

  const file_type = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleChange = (e) => {
    const selected_file = e.target.files[0];
    if (selected_file) {
      if (selected_file && file_type.includes(selected_file.type)) {
        let reader = new FileReader();
        reader.onload = (e) => {
          const workbook = read(e.target.result);
          const sheet = workbook.SheetNames;
          if (sheet.length) {
            const data = utils.sheet_to_json(workbook.Sheets[sheet[0]]);
            setExcelData(data);
          }
        };
        reader.readAsArrayBuffer(selected_file);
      } else {
        setExcelError("Please upload an Excel file");
        setExcelData([]);
      }
    }
  };

  const handleSubmit = async (values, resetForm) => {
    if (excelData.length > 0) {
      const formData = new FormData();

      formData.append("file", file);
      try {
        const response = await axios.post(
          "httpS://websiteapi.kmaschool.in/api/ExcelUpload/UploadExcelFile",

          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Response from the server:", response.data);

        toast.success("Data successfully submitted!", {
          position: toast.POSITION.TOP_CENTER,
        });

        setExcelData([]);
      } catch (error) {
        console.error("Error while uploading file:", error);
        toast.error("Failed to submit data!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      setExcelError("No data to submit");
    }

    resetForm();
  };

  return (
    <div className=" container">
      <ToastContainer />

      <div className="justify-center item-center flex flex-col">
        <Formik
          initialValues={{
            file: null,
          }}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
          {({ handleSubmit }) => (
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-10">
                <label htmlFor="inputName5" className="form-label">
                
                </label>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => {
                    handleChange(e);
                    setFile(e.target.files[0]);
                  }}
                  className="form-control"
                  accept=".xlsx"
                />
              </div>
              <div className="text-center" style={{  display:"flex",justifyContent:"space-between" }}>
                <button  type="submit" className="btn btn-submit btn-primary">
                  Submit
                </button>
                <span>
                  Get Upload Formate
                  <Formate />
                </span>
              </div>
            </form>
          )}
        </Formik>
        <div className="table-responsive">     

        <table className="table table-hover table-striped table-bordered mt-5">
          <thead>
            <tr>
              <th>SR. NO</th>
              <th>Booth</th>
              <th>sr</th>
              <th>District</th>
              <th>Block</th>
              <th>Poll Address</th>
              <th>Name English</th>
              <th>Relation Name English</th>
              <th>Field12</th>
              <th>Hno</th>
              <th>Age</th>
              <th>Gender</th>
            </tr>
          </thead>
          <tbody>
            {excelData.length ? (
              excelData.map((Info, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{Info.Booth}</td>
                  <td>{Info.sr}</td>
                  <td>{Info.District}</td>
                  <td>{Info.Block}</td>
                  <td>{Info.PollAddress}</td>
                  <td>{Info.Relation_Name_English}</td>
                  <td>{Info.Relation_English}</td>
                  <td>{Info.Field12}</td>
                  <td>{Info.Hno}</td>
                  <td>{Info.Age}</td>
                  <td>{Info.Gender}</td>
                </tr>
              ))
            ) : excelError.length ? (
              <tr>
                <td colSpan="12">{excelError}</td>
              </tr>
            ) : (
              <tr>
                <td colSpan="12">No User Data is present</td>
              </tr>
            )}
          </tbody>
        </table>
</div>
      </div>
    </div>
  );
}
