import axios from "axios";
import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import { FormControl, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  CardTitle,
  CardBody,
  Table,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

export default function AddState() {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = () => {
    axios
      .get("https://websiteapi.kmaschool.in/api/panel/getlist/state")
      .then((resp) => setData(resp.data));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleEdit = (item) => {
    setSelectedItem(item);
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (selectedItem) {
      // Update existing item
      const { id } = selectedItem;

      try {
        await axios.put(
          `https://websiteapi.kmaschool.in/api/panel/put/state/${id}`,
          values
        );

        toast.success("Data updated successfully");
        getData();
        resetForm();
        setSelectedItem(null);
      } catch (error) {
        toast.error("Failed to update data");
      }
    } else {
      // Submit new item
      try {
        await axios.post(
          "https://websiteapi.kmaschool.in/api/panel/post/state",
          values
        );
        toast.success("Data added successfully");
        getData();
        resetForm();
      } catch (error) {
        toast.error("Failed to add data");
      }
    }
  };

  const handleDelete = async (id) => {
    let Delete = await fetch(
      `https://websiteapi.kmaschool.in/api/panel/delete/state/${id}`,
      {
        method: "POST",
      }
    );
    toast.success("Data deleted successfully");
    getData();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("State Name is required"),
    isActive: Yup.string().required("IsActive is required"),
  });

  return (
    <div>
      <div className=" ">
        <Card className="add-card">
          <CardBody>
            {selectedItem != null ? (
              <CardTitle style={{ justifyContent:'center',textAlign:'center'}} tag="h5">Update State</CardTitle>
            ) : (
              <CardTitle style={{ justifyContent:'center',textAlign:'center'}} tag="h5">ADD State</CardTitle>
            )}

            <Formik
              enableReinitialize
              initialValues={{
                name: selectedItem ? selectedItem.name : "",
                isActive:
                  selectedItem && selectedItem.isActive
                    ? selectedItem.isActive.toString()
                    : "1",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleReset, errors, touched }) => (
                <Form className="row g-3"  >
                  <FormControl className="col-md-10"sx={{width: "100%" }}>
                    <TextField
                      id="mySelect"
                      type="text"
                      label="State_Name"
                      name="name"
                      InputLabelProps={{ shrink: true }}
                      value={values.name}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </FormControl>
                  <div
                          style={{
                            paddingLeft: "40px",
                            borderBlockColor: "blue",
                          }}
                        >
                          <div className="form-check">
                            <label>
                              <b> IsActive</b>
                            </label>
                            <br />
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              id="exampleRadios1"
                              onChange={handleChange}
                              value={"1"}
                              checked={values.isActive === "1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                            Active
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="isActive"
                              id="exampleRadios2"
                              onChange={handleChange}
                              value={"0"}
                              checked={values.isActive === "0"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              Inactive
                            </label>
                          </div>
                        </div>
                  <div className="text-center">
                    {selectedItem ? (
                      <>
                        <Button type="submit" color="primary">
                          Update
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => {
                            handleReset();
                            setSelectedItem(null);
                          }}
                          className="mx-2"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <Card className="show-card">
          <CardBody>
            <CardTitle style={{ justifyContent:'center',textAlign:'center'}} tag="h5">State List</CardTitle>
            <div className="table-responsive">
            <Table className="table table-hover table-striped table-bordered mt-5">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  {/* <th>ID</th> */}
                  <th>State Name</th>
                  <th colSpan={2} style={{ textAlign: "center" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {/* <td>{item.id}</td> */}
                    <td>{item.name}</td>
                    <td>
                      <IconButton
                        onClick={() => handleEdit(item)}
                        color="success"
                        aria-label="Edit"
                      >
                        <EditIcon />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton
                        onClick={() => handleDelete(item.id)}
                        color="warning"
                        aria-label="Delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table></div>
          </CardBody>
        </Card>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}
