import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom"; 
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import voterImg from "../../assets/img/img1.png";
import logoImg from "../../assets/img/logo1.png";
import "../layout/Header/Header.css";
import { Provider } from "../Context";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function StaffHeaders() {
  const context = useContext(Provider);
  const navRef = useRef(null);
  
  const [isToggled, setIsToggled] = useState(false);
  const sidebarRef = useRef(null);

  
  useEffect(() => {
    if (isToggled) {
      document.body.classList.add("toggle-sidebar");
      // Attach a click event listener to the document to close the sidebar when clicking outside of it
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.body.classList.remove("toggle-sidebar");
      // Remove the click event listener when the sidebar is closed
      document.removeEventListener("click", handleDocumentClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isToggled]);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const handleDocumentClick = (event) => {
    // Check if the click target is outside the sidebar
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      !navRef.current.contains(event.target)
    ) {
      setIsToggled(false); // Close the sidebar
    }
  };

  const handleLogout = () => {
    context.logout();
  };

  

  return (
    <>
      
      <header
        style={{ backgroundColor: "#ffff" }}
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        
        <div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            ref={sidebarRef}
            onClick={handleToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="d-flex align-items-center">
          <Link
            to="/"
            className="logo d-flex align-items-center justify-content-center"
          >
            <img src={logoImg} alt="logo" />
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-center w-100 ">
          <div className="div2">
          
          </div>
       
        </div>
        

        {/*Navbar Headers*/}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <Link
                className="nav-link nav-profile d-flex align-items-center pe-0"
                to="#"
                data-bs-toggle="dropdown"
              >
                <AccountCircleIcon />
              </Link>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li
                  className="dropdown-item d-flex align-items-center"
                  onClick={handleLogout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>
                    <b>Sign Out</b>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        
      </header>
  

      <div ref={navRef}>
        <aside
          style={{ backgroundColor: "#0c4692" }}
          id="sidebar"
          className="sidebar"
        >
          <Link
            to="/"
            className="d-flex align-items-center justify-content-center"
          >
            <img
              style={{ height: "120px", width: "170px" }}
              src={voterImg}
              alt="constituency"
            />
          </Link>
          <ul className="sidebar-nav" id="sidebar-nav">
           
 
            <li className="nav-heading">Voter</li>
            <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#components-nav1"
                data-bs-toggle="collapse"
                to="#"
              >
                <EmojiEventsIcon sx={{ color: "black" }} />
                <sapn style={{ color: "gray", paddingLeft: "63px" }}>
                  Voter
                  <i
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      paddingLeft: "75px",
                    }}
                    className="bi bi-chevron-down ms-auto"
                  ></i>
                </sapn>
              </Link>
              <ul
                id="components-nav1"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to="/voterdetails">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                     Voters Detail
                    </span>
                  </Link>
                </li>
               
               
              </ul>
            </li>
           
           
            <li className="nav-item">
              <ul
                id="components-nav3"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
 
              </ul>
              <li className="nav-heading">Sign Out</li>
              <li className="nav-item">
                <span className="nav-link " onClick={handleLogout}>
                  <ExitToAppIcon sx={{ color: "black" }} />
                  <sapn style={{ color: "gray", paddingLeft: "49px" }}>
                    Sign Out
                  </sapn>
                </span>
              </li>
            </li>
       
          </ul>
        </aside>
      </div>
    </>
  );
}

export default StaffHeaders;

