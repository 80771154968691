import React from "react";
import { Route, Routes } from "react-router-dom";
import StaffLayout from "./components/StaffLayout/StaffLayouts";
import Welcomepage from "./components/StaffLayout/pages/extrapage/Welcomepage";
import Voterdetail from "./components/StaffLayout/pages/StaffVoter/Voterdetail";
import Editvoter from "./components/StaffLayout/pages/StaffVoter/Editvoter";
 

export default function StaffRoute() {
  return (
    <>
      <Routes>
        <Route path="/" element={<StaffLayout />}>
          <Route index element={<Welcomepage />} />
          <Route path="/voterdetails" element={<Voterdetail />} />
          
          <Route path="/votereditdetail" element={<Editvoter />} />
        </Route>
      </Routes>
    </>
  );
}
