import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import PopupForm from "../Addvoter/PopupForm";
const Voterlists = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const navigate = useNavigate();
  const [selectedVoter, setSelectedVoter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchKey, setsearchKey] = useState("");

  const filteredData = data.filter((item) => {
    const name = item.name || "";
    const id = item.id ? item.id.toString() : "";
    const lowerCaseName = name.toLowerCase();
    const mobileNo = item.mobileNo ? item.mobileNo.toString() : "";
    const lowerCaseSearchTerm = searchTerm ? searchTerm.toLowerCase() : "";

    return (
      lowerCaseName.includes(lowerCaseSearchTerm) ||
      id.includes(lowerCaseSearchTerm) ||
      mobileNo.includes(lowerCaseSearchTerm)
    );
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize,searchKey]);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get("https://websiteapi.kmaschool.in/api/panel/listsp/entity", {
        params: {
          pageIndex: currentPage,
          pageSize: pageSize,
          searchKey:searchKey,
           
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleView = async (item) => {
    try {
      const response = await axios.get(
        `https://websiteapi.kmaschool.in/api/panel/listbyid/entity/${item.id}`
      );
      setSelectedVoter(response.data);
      setOpenView(true);
      setSelectedItem(response.data);
    } catch (error) {
      console.error("Error fetching voter details:", error);
    }
  };

  const handleEdit = (id) => {
    navigate("/voterlist", { state: { id } });
    setOpenEdit(true);
  };

  const handleDelete = async (id) => {
    try {
      let Delete = await fetch(
        `https://websiteapi.kmaschool.in/api/panel/delete/entity/${id}`,
        {
          method: "POST",
        }
      );

      toast.success("Data deleted successfully");

      // Update the data in state after deletion
      const updatedData = data.filter((item) => item.id !== id);
      setData(updatedData);

      setOpenDelete(false);
      setSelectedItem(null);
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Error deleting data");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
    setCurrentPage(1);
  };

  const handleClose = () => {
    setOpenView(false);
    setOpenEdit(false);
    setOpenDelete(false);
    setSelectedItem(null);
  };

  const pageSizeOptions = [10, 25, 50, 100,  500];

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      fetchData();
      setIsLoading(false);
    }, 20000);
  }, []);
  return (
    <div>
    

      <TableContainer component={Paper}>
      <div className="mx-3 mt-4 d-flex justify-content-between">
      <div>
      <label htmlFor="pageSizeSelect">List Size: </label>
      
        <select
          className="form-select"
          id="pageSizeSelect"
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          {pageSizeOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        </div>
        <div>
    <input
      type="text"
      placeholder="Searching...."
      className="form-control w-100"
      value={searchKey}
      onChange={(e) => setsearchKey(e.target.value)}  
    />
    <div>
      {filteredData.map((item, index) => (
        <div key={index}>
          {item.someField}
        </div>
      ))}
    
  </div>
  </div>
      </div>
      
        <Table className="table table-hover table-striped table-bordered mt-5">
          <TableHead>
            <TableRow>
              <TableCell>SR</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Relative_Name</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <div  className="loading-indicator  ">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
            ) : (
              filteredData.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>{item.srNo}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.parentName}</TableCell>
                  <TableCell>{item.mobileNo}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleView(item)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleEdit(item.id)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(item.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-center align-items-center pagination-container">
        <div className="d-flex justify-content-between align-items-center">
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="text-align-center">Page {currentPage}</span>

          <Button onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </Button>
        </div>
      </div>
      

      <Dialog
        variant="h5"
        component="div"
        style={{
          textAlign: "start",
         
          fontFamily: "Arial, sans-serif",
          fontWeight: "bold",
          color: "blue",
           
        }}
        open={openView}
        onClose={handleClose}
      >
        <DialogTitle >
          <span
            style={{ color: "blue", display: "flex", justifyContent: "center" }}
          >
            Voter Details
          </span>{" "}
          <br />
          <div
            style={{ color: "green" }}
            className="box d-flex justify-content-center "
          >
            <FontAwesomeIcon id="faCheckCircle1" icon={faCheckCircle} />
          </div>
        </DialogTitle>
        <DialogContent>
          {selectedItem && (
            <div className="details-container bg-green d-flex my-5">
              <div className="details-column" style={{ marginRight: "20px" }}>
                <Typography style={{ fontSize: "14px" }}>
                  <b>ID:</b> {selectedItem.id}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Name:</b> {selectedItem.name}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Relation With:</b> {selectedItem.parent}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Relative Name:</b> {selectedItem.parentName}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Gender:</b> {selectedItem.gender}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>DOB:</b> {moment(selectedItem.dob).format("MM-DD-YYYY")}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Age:</b> {moment().diff(moment(selectedItem.dob), "years")}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Contact:</b> {selectedItem.mobileNo}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Aadhar:</b> {selectedItem.adharNo}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>House No:</b> {selectedItem.houseNo}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Voter No:</b> {selectedItem.srNo}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Voter ID:</b> {selectedItem.voterId}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Profession:</b> {selectedItem.profession}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Vehicle Type:</b> {selectedItem.vicile}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Vehicle Number:</b> {selectedItem.vehiclestatus}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Family ID:</b> {selectedItem.familyID}
                </Typography>
              </div>
              <div className="details-column">
                <Typography style={{ fontSize: "14px" }}>
                  <b>Total In Family:</b> {selectedItem.membersinfamily}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Family Head Status:</b>{" "}
                  {selectedItem.familyHeadType === 0
                    ? "No"
                    : selectedItem.familyHeadType === 1
                    ? "Yes"
                    : "Unknown"}
                </Typography>

                <Typography style={{ fontSize: "14px" }}>
                  <b>Role:</b> {selectedItem.roles}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Voter View:</b> {selectedItem.political}
                </Typography>
                <Typography style={{ color: "red", fontSize: "15px" }}>
                  <b>Permanent-Address:</b>
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>State:</b> {selectedItem.states}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>District:</b> {selectedItem.district}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>City:</b> {selectedItem.location}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Address:</b> {selectedItem.address}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Booth:</b> {selectedItem.booth}
                </Typography>
                <Typography style={{ color: "red", fontSize: "15px" }}>
                  <b>Current-Address:</b>
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>State:</b> {selectedItem.states1}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>District:</b> {selectedItem.district1}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>City: </b>
                  {selectedItem.city1}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <b>Address:</b> {selectedItem.address1}
                </Typography>
              </div>
            </div>
          )}
        </DialogContent>
        <Button variant="contained" color="info" onClick={handleClose}>
          Close
        </Button>
      </Dialog>
    
      {/* {/ Edit Dialog /} */}
      <Dialog open={openEdit} onClose={handleClose}>
        <PopupForm
          voterData={selectedVoter}
          onClose={() => setOpenEdit(false)}
        />
      </Dialog>
      {/* {/ Delete Dialog /} */}
      <Dialog open={openDelete} onClose={handleClose}>
        <DialogTitle>Delete Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default Voterlists;
