import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Provider } from "../../Context";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity3TwoToneIcon from "@mui/icons-material/Diversity3TwoTone";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SettingsIcon from "@mui/icons-material/Settings";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeshboardIcon from "./Navbutton/DeshboardIcon";
import VoterIcon from "./Navbutton/VoterIcon";
import MasterTypeIcon from "./Navbutton/MasterTypeIcon";
import LocationIcon from "./Navbutton/LocationIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import voterImg from "../../../assets/img/img1.png";
import logoImg from "../../../assets/img/logo1.png";
import "./Header.css";
import StaffIcon from "./Navbutton/StaffIcon";

function Header() {
  const context = useContext(Provider);
  const navRef = useRef(null);

  const [isToggled, setIsToggled] = useState(null);
  const sidebarRef = useRef(null);
 
   


  useEffect(() => {
    if (isToggled) {
      document.body.classList.add("toggle-sidebar");
      // Attach a click event listener to the document to close the sidebar when clicking outside of it
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.body.classList.remove("toggle-sidebar");
      // Remove the click event listener when the sidebar is closed
      document.removeEventListener("click", handleDocumentClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isToggled]);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const handleDocumentClick = (event) => {
    // Check if the click target is outside the sidebar
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      !navRef.current.contains(event.target)
    ) {
      setIsToggled(false); // Close the sidebar
    }
  };

  const handleLogout = () => {
    context.logout();
  };

  return (
    <>
      <header
        style={{ backgroundColor: "#ffff" }}
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            ref={sidebarRef}
            onClick={handleToggle}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="d-flex align-items-center">
          <Link
            to="/"
            className="logo d-flex align-items-center justify-content-center"
          >
            <img src={logoImg} alt="logo" />
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-center w-100 ">
          <div className="div2">
            <IconButton>
              <DeshboardIcon />
            </IconButton>
            <IconButton>
              <StaffIcon />
            </IconButton>
            <IconButton>
              <VoterIcon />
            </IconButton>
            <IconButton>
              <MasterTypeIcon />
            </IconButton>
            <IconButton>
              <LocationIcon />
            </IconButton>
          </div>
        </div>

        {/*Navbar Headers*/}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <Link
                className="nav-link nav-profile d-flex align-items-center pe-0"
                to="#"
                data-bs-toggle="dropdown"
              >
                <AccountCircleIcon />
              </Link>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li
                  className="dropdown-item d-flex align-items-center"
                  onClick={handleLogout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>
                    <b>Sign Out</b>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      <div ref={navRef}>
        <aside
          style={{ backgroundColor: "#0c4692" }}
          id="sidebar"
          className="sidebar"
        >
          <Link
            to="/"
            className="d-flex align-items-center justify-content-center"
          >
            <img
              style={{ height: "120px", width: "170px" }}
              src={voterImg}
              alt="constituency"
            />
          </Link>
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-heading">Deshboard</li>
            <li className="nav-item">
              <Link className="nav-link " to="/">{" "}
                <DashboardIcon sx={{ color: "black" }} />
                <sapn style={{ color: "gray", paddingLeft: "49px" }}>
                  Dashboard
                </sapn>
              </Link>
            </li>

            <li className="nav-heading">Staff</li>
             <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#components-nav10"
                data-bs-toggle="collapse"
                to="#"
              >
                <Diversity3TwoToneIcon sx={{ color: "black" }} />
                <sapn style={{ color: "gray", paddingLeft: "50px" }}>
                  Staff
                  <i
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      paddingLeft: "92px",
                    }}
                    className="bi bi-chevron-down ms-auto"
                  ></i>
                </sapn>
              </Link>
              <ul
                id="components-nav10"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to="/addstaff">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add Staff
                    </span>
                  </Link> 
                </li>
                 
              </ul>
              
            </li>
{/* {/ --------------------- /} */}
            <li className="nav-heading">Voter</li>
            <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#components-nav1"
                data-bs-toggle="collapse"
                to="#"
              >
                <EmojiEventsIcon sx={{ color: "black" }} />
                <sapn style={{ color: "gray", paddingLeft: "63px" }}>
                  Voter
                  <i
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      paddingLeft: "75px",
                    }}
                    className="bi bi-chevron-down ms-auto"
                  ></i>
                </sapn>
              </Link>
              <ul
                id="components-nav1"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to="/addvoter">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add Voter
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/voterlist">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Voter List
                    </span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/voterpdf">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Get PDF
                    </span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/voterexcel">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Get Excel
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/uploadfile">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Upload Excel
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-heading">Master Type</li>
            <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#components-nav2"
                data-bs-toggle="collapse"
                to="#"
              >
                <SettingsIcon sx={{ color: "black" }} />
                <sapn style={{ color: "gray", paddingLeft: "50px" }}>
                  Master Type
                  <i
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      paddingLeft: "40px",
                    }}
                    className="bi bi-chevron-down ms-auto"
                  ></i>
                </sapn>
              </Link>
              <ul
                id="components-nav2"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to="/mastertype">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add MasterType
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/mastertypedetail">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add MasterTypeDetail
                    </span>
                  </Link>
                  <Link to="/masterTypedetailparent">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add Parent
                    </span>
                  </Link>
                  <Link to="/addblock">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add Block
                    </span>
                  </Link>
                  <Link to="/addbooth">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add Booth
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-heading">Location </li>
            <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#components-nav3"
                data-bs-toggle="collapse"
                to="#"
              >
                <LocationOnIcon sx={{ color: "black" }} />
                <sapn style={{ color: "gray", paddingLeft: "50px" }}>
                  Locations
                  <i
                    style={{
                      color: "black",
                      fontSize: "1.1rem",
                      paddingLeft: "60px",
                    }}
                    className="bi bi-chevron-down ms-auto"
                  ></i>
                </sapn>
              </Link>
              <ul
                id="components-nav3"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to="/addstate">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add State
                    </span>
                  </Link>{" "}
                </li>
                <li>
                  <Link to="/adddistrict">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add District
                    </span>
                  </Link>
                  <Link to="/addcity">
                    <FiberManualRecordIcon />
                    <span style={{ color: "#999999", paddingLeft: "20px" }}>
                      Add City
                    </span>
                  </Link>
                </li>
              </ul>
              <li className="nav-heading">Sign Out</li>
              <li className="nav-item">
                <span className="nav-link " onClick={handleLogout}>
                  <ExitToAppIcon sx={{ color: "black" }} />
                  <sapn style={{ color: "gray", paddingLeft: "49px" }}>
                    Sign Out
                  </sapn>
                </span>
              </li>
            </li>
          </ul>
        </aside>
      </div>
    </>
  );
}

export default Header;
