import axios from "axios";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
 
} from "@mui/material"; 
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card, CardTitle, CardBody, Table, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";

export default function AddStaff() {
 
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [booth, setBooth] = useState([]);

  const getData = () => {
    axios
      .get("https://websiteapi.kmaschool.in/api/panel/list/staff")
      .then((resp) => setData(resp.data));
  };

  useEffect(() => {
    getData();
    Getbooth();
  }, []);

  const Getbooth = () => {
    fetch(`https://websiteapi.kmaschool.in/api/panel/getlist/both`)
      .then((response) => response.json())
      .then((data) => setBooth(data));
    setBooth(data);
  };
  const handleEdit = (item) => {
    setSelectedItem(item);
  };
  

  const handleSubmit = async (values, { resetForm }) => {
    if (selectedItem) {
      // Update existing item
      const { id } = selectedItem;

      try {
        await axios.put(
          `https://websiteapi.kmaschool.in/api/panel/put/staff/${id}`,
          values
        );

        toast.success("Data updated successfully");
        getData();
        resetForm();
        setSelectedItem(null);
      } catch (error) {
        toast.error("Failed to update data");
      }
    } else {
      // Submit new item
      try {
        await axios.post("https://websiteapi.kmaschool.in/api/panel/post/staff", values);
        toast.success("Data added successfully");
        getData();
        resetForm();
      } catch (error) {
        toast.error("Failed to add data");
      }
    }
  };
  

  const handleDelete = async (id) => {
    let Delete = await fetch(
      `https://websiteapi.kmaschool.in/api/panel/delete/staff/${id}`,
      {
        method: "POST",
      }
    );
    toast.success("Data deleted successfully");
    getData();
  };

 

  return (
    <div>
      <div>
        <Card className="add-card">
          <CardBody>
            {selectedItem != null ? (
              <CardTitle
                style={{ justifyContent: "center", textAlign: "center" }}
                tag="h5"
              >
                Update Staff
              </CardTitle>
            ) : (
              <CardTitle
                style={{ justifyContent: "center", textAlign: "center" }}
                tag="h5"
              >
                ADD Staff
              </CardTitle>
            )}

            <Formik
              enableReinitialize
              initialValues={{
                name: selectedItem ? selectedItem.name : "",
                fatherName: selectedItem ? selectedItem.fatherName : "",
                mobileNo: selectedItem ? selectedItem.mobileNo : "",
                emailId: selectedItem ? selectedItem.emailId : "",
                address: selectedItem ? selectedItem.address : "",
                bothId: selectedItem ? selectedItem.bothId : "",
                userName: selectedItem ? selectedItem.userName : "",
                password: selectedItem ? selectedItem.password : "",
                isActive:
                  selectedItem && selectedItem.isActive
                    ? selectedItem.isActive.toString()
                    : "1",
              }}
         
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleReset, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-3 my-3">
                      <TextField
                        id="mySelect"
                        type="text"
                        label="Staff_Name"
                        name="name"
                        InputLabelProps={{ shrink: true }}
                        value={values.name}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <TextField
                        id="mySelect"
                        type="text"
                        name="fatherName"
                        InputLabelProps={{ shrink: true }}
                        label="Father Name"
                        value={values.fatherName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <TextField
                        type="email"
                        name="emailId"
                        label="Email-Id"
                        value={values.emailId}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <TextField
                        type="text"
                        name="mobileNo"
                        label="Mobile No"
                        value={values.mobileNo}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <TextField
                        type="text"
                        name="address"
                        label="Address"
                        value={values.address}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        
                      />
                    </div>

                    <FormControl className="col-md-3 my-3">
                      <InputLabel>Booth</InputLabel>
                      <Select
                        name="bothId"
                        label="Booth"
                        value={values.bothId}
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <b>Select Booth:---</b>
                        </MenuItem>
                        {Array.isArray(booth) &&
                          booth.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <div className="col-md-3 my-3">
                      <TextField
                        type="text"
                        name="userName"
                        label="User Name"
                        value={values.userName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <TextField
                        type="text"
                        name="password"
                        label="Password"
                        value={values.password}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                       required
                      />
                    </div>
                    <div
                          style={{
                            paddingLeft: "40px",
                            borderBlockColor: "blue",
                          }}
                        >
                          <div className="form-check">
                            <label>
                              <b>Authority</b>
                            </label>
                            <br />
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              id="exampleRadios1"
                              onChange={handleChange}
                              value={"1"}
                              checked={values.isActive === "1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                            Enable
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="isActive"
                              id="exampleRadios2"
                              onChange={handleChange}
                              value={"0"}
                              checked={values.isActive === "0"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              Disable
                            </label>
                          </div>
                        </div>
                  </div>

                  <div className="text-center">
                    {selectedItem ? (
                      <>
                        <Button type="submit" color="primary">
                          Update
                        </Button>
                        <Button
                          type="button"
                          color="danger"
                          onClick={() => {
                            handleReset();
                            setSelectedItem(null);
                          }}
                          className="mx-2"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <Card className="show-card">
          <CardBody>
            <CardTitle
              style={{ justifyContent: "center", textAlign: "center" }}
              tag="h5"
            >
              Staff List
            </CardTitle>
            <div className="table-responsive">
              <Table className="table table-hover table-striped table-bordered mt-5">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                     
                    <th>Name</th>
                    <th>Father Name</th>
                    <th>Mobile Number</th>
                    <th>EmailId</th>
                    <th>Address</th>
                    <th>Booth</th>
                    <th>UserId</th>
                    <th>Password</th>
                    <th>Permission</th>

                    <th colSpan={2} style={{ textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      
                      <td>{item.name}</td>
                      <td>{item.fatherName}</td>
                      <td>{item.mobileNo}</td>
                      <td>{item.emailId}</td>
                      <td>{item.address}</td>
                      <td>{item.bothId}</td>
                      <td>{item.userName}</td>
                      <td>{item.password}</td>
                      {/* <td>{item.isActive}</td> */}
                      <td>
                    {item?.isActive === 0
                      ? "Disabled"
                      : item?.isActive === 1
                      ? "Yes"
                      : "Active"}
                  </td>
 
                      <td>
                        <IconButton
                          onClick={() => handleEdit(item)}
                          color="success"
                          aria-label="Edit"
                        >
                          <EditIcon />
                        </IconButton>
                      </td>
                      <td>
                        <IconButton
                          onClick={() => handleDelete(item.id)}
                          color="warning"
                          aria-label="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}
