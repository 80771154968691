import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./Dashboard.css";
import { fetchReport } from "../../Constant/IndexApi";

export default function Dashboard() {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const getReportData = async () => {
    try {
      let res = await fetchReport();
      setData(res.data);
      setFilteredData(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getReportData();
  }, []);
  return (
    <>
      <div className="deshboard1" >
        <Grid  id="container" container >
          <Grid item className="item1"  >
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Voters <br />
                    <span>
                      {filteredData.totalVoter}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography  variant="h6" component="div">
                    Voters
                    <br />
                    <span  >
                      {filteredData.totalVoter}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Booth
                    <br />
                    <span>
                      {filteredData.totalBoth}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Booth
                    <br />{" "}
                    <span >
                      {filteredData.totalBoth}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Male <br />{" "}
                    <span >
                      {filteredData.totalMale}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Male <br />
                    <span >
                      {filteredData.totalMale}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item  className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Female <br />
                    <span >
                      {filteredData.totalFemale}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Female
                    <br />
                    <span>
                      {filteredData.totalFemale}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Govt.Employee
                    <br />
                    <span style={{ marginLeft: "50px" }}>
                      {filteredData.totalGovtEmployee}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Govt.Employee
                    <br />{" "}
                    <span style={{ marginLeft: "50px" }}>
                      {filteredData.totalGovtEmployee}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item  className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Pvt.Employee
                    <br />
                    <span style={{ marginLeft: "54px" }}>
                      {filteredData.totalPrivateEmployee}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Pvt.Employee
                    <br />{" "}
                    <span style={{ marginLeft: "54px" }}>
                      {filteredData.totalPrivateEmployee}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Ex. Employee
                    <br />
                    <span style={{ marginLeft: "50px" }}>
                      {filteredData.totalExservice}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                  Ex. Employee
                    <br />{" "}
                    <span style={{ marginLeft: "50px" }}>
                      {filteredData.totalExservice}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Businessmen
                    <br />
                    <span style={{ marginLeft: "44px" }}>
                      {filteredData.totalBusinessmen}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Businessmen
                    <br />{" "}
                    <span style={{ marginLeft: "44px" }}>
                      {filteredData.totalBusinessmen}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Farmer
                    <br />
                    <span style={{ marginLeft: "20px" }}>
                      {filteredData.totalFarmer}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Farmer
                    <br />{" "}
                    <span style={{ marginLeft: "20px" }}>
                      {filteredData.totalFarmer}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Labour
                    <br />
                    <span style={{ marginLeft: "14px" }}>
                      {filteredData.totalLabour}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                    Labour
                    <br />{" "}
                    <span style={{ marginLeft: "14px" }}>
                      {filteredData.totalLabour}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    Student
                    <br />
                    <span style={{ marginLeft: "14px" }}>
                      {filteredData.totalStudent}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                  Student
                    <br />{" "}
                    <span style={{ marginLeft: "14px" }}>
                      {filteredData.totalStudent}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item className="item1">
            <Card className="dashboard-card mt-4">
              <div className="card-inner">
                <CardContent className="card-front">
                  <Typography variant="h6" component="div">
                    House Wife
                    <br />
                    <span style={{ marginLeft: "40px" }}>
                      {filteredData.totalHousewife}
                    </span>
                  </Typography>
                </CardContent>
                <CardContent className="card-back">
                  <Typography variant="h6" component="div">
                  House Wife
                    <br />{" "}
                    <span style={{ marginLeft: "40px" }}>
                      {filteredData.totalHousewife}
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
