import {  
  FormGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState,useRef} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {

  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { fetchBlockList } from "../../../Constant/IndexApi";
import * as Yup from "yup";

export default function Block() {
  const [data, setData] = useState([]);
  const [getbyid, setGetbyid] = useState(null);
  const [editid, setEditid] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  
  const [filteredData, setFilteredData] = useState([]);
  const handleStateChange = (event) => {
    const districId = event.target.value;
    setSelectedItem(districId);

    if (districId) {
      const filtered = data.filter((item) => item.districId === districId);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };
  useEffect(() => {
    fetch("https://websiteapi.kmaschool.in/api/panel/getlist/district")
      .then((response) => response.json())
      .then((data) => setOptions(data));
  }, []);
 
  const getBlockReport = async () => {
    try {
      let res = await fetchBlockList();
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlockReport();
  }, []);

  const EditPriority = async (id, values) => {
    try {
      await axios.put(
        `https://websiteapi.kmaschool.in/api/panel/put/block/${id}`,
        values
      );
  
      toast.success("Data successfully updated");
      setGetbyid(null);
       
      setEditid(null);
      getBlockReport();
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data");
    }
  };

   
  const handleEdit = async (id, e) => {
    try {
      e.preventDefault();
      const response = await fetch(
        `https://websiteapi.kmaschool.in/api/panel/getbyid/block/${id}`
      );
      if (response.ok) {
        const data = await response.json();
        setGetbyid(data);
        setEditid(id);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await axios.post("https://websiteapi.kmaschool.in/api/panel/post/block", values);
      toast.success("Data successfully added");
      getBlockReport();
 
      resetForm();
    } catch (error) {
      console.error("Failed to add data:", error);
      toast.error("Failed to add data");
    }
  };

  const handleDelete = async (id) => {
    let Delete = await fetch(
      `https://websiteapi.kmaschool.in/api/panel/delete/block/${id}`,
      {
        method: "POST",
      }
    );
    toast.success("Data deleted successfully");
    getBlockReport();
  };

  const handleView = async (id) => {
    try {
      const response = await fetch(
        `https://websiteapi.kmaschool.in/api/panel/getbyid/block/${id}`
      );
      if (response.ok) {
        const data = await response.json();
        setGetbyid(data);
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const validationSchema = Yup.object().shape({
    districtId: Yup.string().required("District is required"),
    name: Yup.string().required("Block Name is required"),
    
  });
  const formikRef = useRef(null);
  const handleResetForm = () => {
    formikRef.current.resetForm();
    setGetbyid(null);
    setEditid(null);
  };

  return (
    <div>
      <div >
        <Card className="add-card">
          <CardBody>
            <CardTitle style={{ textAlign:'center'}}tag="h5">
              <b>
              {editid!=null?( <b className="card-title">Update Block</b>):( <b className="card-title">Add Block</b>)}
              </b>
            </CardTitle>
            <Formik
             innerRef={formikRef}
              enableReinitialize
              initialValues={{
                districtId:getbyid?.districtId || "",
                name:   getbyid?.name || "",
            //isActive:  getbyid?.isActive  || "1",
           isActive: getbyid ? getbyid.isActive.toString() : "1",

              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleReset,
                isSubmitting,
                resetForm,
                touched,
                errors,
              }) => (
                <Form id="myForm" className="row g-3" onSubmit={handleSubmit}>
                
                    <FormControl className="col-md-10"sx={{ width: "100%" }}>
                      <InputLabel>Select District</InputLabel>
                      <Select
                        id="mySelect"
                        name="districtId"
                        type="text"
                        label="Select District"
                        value={values.districtId}
                        onChange={(event) => {
                          handleChange(event);
                          handleStateChange(event);
                        }}
                        error={touched.districtId && Boolean(errors.districtId)}
                      >
                        <MenuItem value="">--Select District--</MenuItem>
                        {options.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.districtId && errors.districtId && (
                        <div className="error">{errors.districtId}</div>
                      )}
                    </FormControl>
  
                    <FormGroup className="col-md-10" sx={{ width: "100%" }}>
                    <TextField
                      type="text"
                      name="name"
                      label="Insert Block Name"
                      InputLabelProps={{ shrink: true }}
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                    />
                    {touched.name && errors.name && (
                      <div className="error">{errors.name}</div>
                    )}
                    <div
                          style={{
                            paddingLeft: "40px",
                            borderBlockColor: "blue",
                          }}
                        >
                          <div className="form-check">
                            <label>
                              <b> IsActive</b>
                            </label>
                            <br />
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              id="exampleRadios1"
                              onChange={handleChange}
                              value={"1"}
                              checked={values.isActive === "1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                            Active
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="isActive"
                              id="exampleRadios2"
                              onChange={handleChange}
                              value={"0"}
                              checked={values.isActive === "0"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              Inactive
                            </label>
                          </div>
                        </div>
                  </FormGroup>
                  <div className="text-center">
                    {editid ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => EditPriority(editid, values)}
                          className="btn btn-submit btn-primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          onClick={handleResetForm}
                          className="btn btn-submit btn-danger mx-2"
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-submit btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
        <div>
          <section className="section">
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card list-card">
                    <div className="card-body">
                      <h5 style={{ textAlign:'center'}}className="card-title">Block List</h5>
                      <div className="table-responsive">
                      <table className="table table-hover table-striped table-bordered mt-5">
                        <thead>
                          <tr>
                            <th>Sr.No</th>
                            <th>Block Name</th>
                            
                            <th>District</th>
                            <th>IsActive</th>
                            <th colSpan={3} style={{ textAlign: "center" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              
                              <td>{item.district}</td>
                              <td>{item.isActive ? "Active" : "Inactive"}</td>
                              <td>
                                <IconButton
                                  onClick={(e) => handleEdit(item.id, e)}
                                  color="success"
                                  aria-label="Edit"
                                >
                                  <EditIcon />
                                </IconButton>
                              </td>
                              <td>
                                <IconButton
                                  onClick={() => handleDelete(item.id)}
                                  color="warning"
                                  aria-label="Delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}
