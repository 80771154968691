import { IconButton } from "@mui/material";
import axios from "axios";
import { Field, Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormGroup, Label } from "reactstrap";
import * as Yup from "yup";

export default function AddCity() {
  const formikRef = useRef(null);
  const [data, setData] = useState([]);
  const [getbyid, setGetbyid] = useState(null);
  const [editid, setEditid] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [state, setState] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const handleStateChange = (event) => {
    const districtId = event.target.value;
    setSelectedItem(districtId);

    if (districtId) {
      const filtered = data.filter((item) => item.districtId === districtId);
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

   

  const GetData = () => {
    axios
      .get("https://websiteapi.kmaschool.in/api/panel/getlist/city")
      .then((resp) => {
        setData(resp.data);
        setFilteredData(resp.data);
      });
  };

  useEffect(() => {
    GetData();
  }, []);

  const EditPriority = async (id, values) => {
    try {
      await axios.put(
        `https://websiteapi.kmaschool.in/api/panel/put/city/${id}`,
        values
      );
      document.getElementById("myForm").reset();
      document.getElementById("mySelect").value = "";
      toast.success("Data successfully updated");
      setGetbyid(null);
      GetData();
      setEditid(null);
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update data");
    }
  };
  const handleEdit = async (id) => {
    let get = await fetch(
      `https://websiteapi.kmaschool.in/api/panel/getById/city/${id}`
    );
    if (get.ok) {
      get = await get.json();
       setGetbyid(get);
    //  setGetbyid(data);
      setEditid(id);
     

      GetDistrict(get.stateId);
     
      
    }
  };
  useEffect((id) => {
    if (id != null) {
      handleEdit();
    }
  }, [1]);

  const handleSubmit = async (values, resetForm) => {
    try {
      await axios.post(
        "https://websiteapi.kmaschool.in/api/panel/post/city",
        values
      );
      toast.success("Data successfully added");
      document.getElementById("myForm").reset();
      resetForm();
      GetData();
    } catch (error) {
      console.error("Failed to add data:", error);
      toast.error("Failed to add data");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(
        `https://websiteapi.kmaschool.in/api/panel/delete/city/${id}`
      );
      toast.success("Data deleted successfully");
      GetData();
    } catch (error) {
      console.error("Failed to delete data:", error);
      toast.error("Failed to delete data");
    }
  };


  


  useEffect(() => {
    fetch("https://websiteapi.kmaschool.in/api/panel/getState")
      .then((response) => response.json())
      .then((data) => setState(data));
    }, []);
  const GetDistrict = (id) => {
    fetch(`https://websiteapi.kmaschool.in/api/panel/getDistrict/${id}`)
      .then((response) => response.json())
      .then((data) => setDistricts(data));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("City Name is required"),
    stateId: Yup.string().required("State is required"),
    districtId: Yup.string().required("District is required"),
    isActive: Yup.string().required("IsActive is required"),
  });
  const handleResetForm = () => {
    formikRef.current.resetForm();
    setGetbyid(null);
    setEditid(null);
  };

  return (
    <div>
      <div className=" ">
        <div className="card add-card">
          <div className="card-body">
            {editid != null ? (
              <h5
              style={{ justifyContent:'center',textAlign:'center'}}
              className="card-title"
            >
              Update City
            </h5> 
            ) : (
              <h5
              style={{ justifyContent:'center',textAlign:'center'}}
              className="card-title"
            >
              Add City
            </h5> 
            )}
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={{
                name: getbyid?.name || "",
                districtId: getbyid?.districtId || "",
                stateId: getbyid?.stateId || "",
                isActive: getbyid?.isActive.toString() || "1",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleReset,
                isSubmitting,
                resetForm,
                errors,
                touched,
              }) => (
                <form id="myForm" className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-10" style={{width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="inputName5">State List</InputLabel>
                      <Select
                        labelId="inputName5"
                        id="mySelect"
                        name="stateId"
                        label="State List"
                        value={values.stateId}
                        onChange={(e) => {
                          handleChange(e);
                          GetDistrict(e.target.value);
                        }}
                        error={touched.stateId && errors.stateId}
                      >
                        <MenuItem value="">
                          <b>--Select State--</b>
                        </MenuItem>
                        {state.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.stateId && errors.stateId && (
                        <div style={{ color: "red" }}>{errors.stateId}</div>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-md-10" style={{width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel>District</InputLabel>
                      <Select
                        name="districtId"
                        label="District"
                        value={values.districtId}
                        onChange={handleChange}
                        error={touched.districtId && errors.districtId}
                      >
                        <MenuItem value="">
                          <b>--Select District--</b>
                        </MenuItem>

                        {districts.map((item, id) => (
                          <MenuItem key={id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.districtId && errors.districtId && (
                        <div style={{ color: "red" }}>{errors.districtId}</div>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-md-10" style={{width: "100%" }}>
                    <TextField
                      id="inputName5"
                      label="Name"
                      name="name"
                      value={values.name}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      error={touched.name && errors.name}
                    />
                    {touched.name && errors.name && (
                      <div style={{ color: "red" }}>{errors.name}</div>
                    )}
                  </div>
                  <div style={{ paddingLeft: "40px" }}>
                  <div
                          style={{
                            paddingLeft: "40px",
                            borderBlockColor: "blue",
                          }}
                        >
                          <div className="form-check">
                            <label>
                              <b> IsActive</b>
                            </label>
                            <br />
                            <input
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              id="exampleRadios1"
                              onChange={handleChange}
                              value={"1"}
                              checked={values.isActive === "1"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                            Active
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="isActive"
                              id="exampleRadios2"
                              onChange={handleChange}
                              value={"0"}
                              checked={values.isActive === "0"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios2"
                            >
                              Inactive
                            </label>
                          </div>
                        </div>
                  </div>
                  <div className="text-center">
                    {editid ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => EditPriority(editid, values)}
                          className="btn btn-submit btn-primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          onClick={handleResetForm}
                          className="btn btn-submit btn-danger mx-2"
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-submit btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              )}
            </Formik>
            <div className="card-body">
              <h5
                style={{ justifyContent:'center',textAlign:'center'}}
                className="card-title"
              >
                City List
              </h5>
              <div className="table-responsive">
              <table className="table table-striped table-bordered mt-5">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>City Name</th>
                    <th>District Name</th>
                    <th>State Name</th>
                    {/* <th>IsActive</th> */}
                    <th colSpan={2} style={{ textAlign: "center" }}>Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                    <td>{index+1}</td>
                      <td>{item.name}</td>
                      <td>{item.district}</td>
                      <td>{item.state}</td>
                      {/* <td>{item.isActive}</td> */}
                      <td>
                        <IconButton
                          color="success"
                          onClick={(e) => handleEdit(item.id, e)}
                        >
                          <EditIcon />
                        </IconButton>
                      </td>
                      <td>
                        <IconButton
                          color="warning"
                          onClick={(e) => handleDelete(item.id)}
                          className="btn btn-danger"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
               </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
