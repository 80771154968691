import axios from "axios";
const API = axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT });
const API1 = axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT });
 
API.interceptors.request.use((req) => {
  if (localStorage.getItem("token")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  req.headers.common = { "Access-Control-Allow-Origin": "*" };
  return req;
});

//entity voter list {GETALL}
const getEntity = (page, pageSize) =>
  API.get(` /listsp/entity?page=1&pageSize=300`);

//Fillter entity Filter voter list {GETALL}
 
const getEntity1 = (page, pageSize) => API.get(`/getall/filter?pageIndex=1&pageSize=100`);

//Dashboard data list {GETALL}
const getReport = () => API.get("Report/Dashboard");

// const BlockListBoothWise= async(boothId)=> await API.get(`getall/filter?Booth=${boothId}&pageIndex=1&pageSize=1500`)
//Staff data list {GETALL}
const getStaffReport = () => API.get("list/staff");

//Block data list {GETALL}
const getBlockList = () => API.get("getlist/block");

//Booth data list {GETALL}
const getBoothList = () => API.get("getlist/both");

//City data list {GETALL}
const getCityList = () => API.get("getlist/city");

//District data list {GETALL}
const getDistrictList = () => API.get("getlist/district");

// District data list {GET by id }
const getDistrictById = (id) => API.get(`/getdistricts/${id}`);

//State data list {GETALL}
const getStateList = () => API.get("getlist/state");

//Master Type Detail data list {GETALL}
const getMasterdetail = () => API.get("mastertype/details/list");

// eslint-disable-next-line
export default {
  getEntity,
  getEntity1,
  getReport,
  getStaffReport,
  getBlockList,
  getBoothList,
  getCityList,
  getDistrictList,
  getDistrictById,
  getStateList,
  getMasterdetail,
  // BlockListBoothWise,
};
